// Setup the $api object, which is a proxy to $axios.
// Proxy API calls, raising an exception if one of the calls returns
// an error.

import Vue from 'vue';
import store from '@/store';
import {AxiosRequestConfig} from 'axios';
import {IStatusDictionary} from '@/util/apiresponse';
import {ApiCallFailed} from './apicallfailed';


export class APIWrapper {

  setAccessToken(accesToken: string) {
    store.state.axios.defaults.headers.common.Authorization = accesToken;
  }

  async get(url: string, config?: AxiosRequestConfig) {
    const res = await store.state.axios.get<IStatusDictionary>(url, config);
    const statusDict = res.data;
    if (!statusDict.status) {
      throw new ApiCallFailed(statusDict.error_msg || 'Unexpected error',
                              statusDict);
    }
    return statusDict.value;
  }

  async post(url: string, data?: any, config?: AxiosRequestConfig) {
    const res = await this.postRaw(url, data, config);
    return res.value;
  }

  /**
   * Like post but returns the response, not response.value.
   *
   * @param url url to call.
   * @param data data to post.
   * @param config axios configuration.
   */
  async postRaw(url: string, data?: any, config?: AxiosRequestConfig): Promise<IStatusDictionary> {
    const res = await store.state.axios.post<IStatusDictionary>(url, data, config);
    const statusDict = res.data;
    if (!statusDict.status) {
      throw new ApiCallFailed(statusDict.error_msg || 'Unexpected error',
                              statusDict);
    }
    return statusDict;
  }
}

Vue.prototype.$api = new APIWrapper();

import {Module, Mutation, MutationTree} from 'vuex';
import {IState} from '../state';

const debug = process.env.NODE_ENV !== 'production';

// Server side error messages are put into the store.
// Fetching them with veelidates errors.collect sometimes leaves the UI out of
// synch and makes testing harder.

interface IFormErrors {
    [key: string]: string | null;
    firstName: string | null;
    lastName: string | null;
    emailAddress: string | null;
    password: string | null;
    passwordValidate: string | null;
}

// FormState contains errors.
export interface IUserFormState {
    errors: IFormErrors;
}

export const FormState: IUserFormState = {
    errors: {
        firstName: null,
        lastName: null,
        emailAddress: null,
        password: null,
        passwordValidate: null
    }
};

// Mutations
interface IUserFormMutation<S> extends MutationTree<S> {
    updateErrorFirstName: Mutation<S>;
    updateErrorLastName: Mutation<S>;
    updateErrorEmailAddress: Mutation<S>;
    updateErrorPassword: Mutation<S>;
    updateErrorpasswordValidate: Mutation<S>;
}

const mutations: IUserFormMutation<IUserFormState> = {
    // commit('userform/updateAll')
    updateAll(state: IUserFormState, newState: IUserFormState) {
        if (newState == null || !newState.errors) {
            return;
        }

        Object.keys(FormState.errors).forEach((element) => {
            if (newState.errors[element]) {
                state.errors[element] = newState.errors[element];
            } else {
                state.errors[element] = '';
            }
        });
    },

    updateErrorFirstName(state: IUserFormState, error: string) {
        state.errors.firstName = error;
    },

    updateErrorLastName(state: IUserFormState, error: string) {
        state.errors.lastName = error;
    },

    updateErrorEmailAddress(state: IUserFormState, error: string) {
        state.errors.emailAddress = error;
    },

    updateErrorPassword(state: IUserFormState, error: string) {
        state.errors.password = error;
    },

    updateErrorpasswordValidate(state: IUserFormState, error: string) {
        state.errors.passwordValidate = error;
    }
};

export function createModule(state: IUserFormState | null = null): Module<IUserFormState, IState>  {
    if (state == null) {
        state = Object.assign({}, FormState) as IUserFormState;
        state.errors = Object.assign({},
            {
                firstName: null,
                lastName: null,
                emailAddress: null,
                password: null,
                passwordValidate: null
            });
    }

    return {
        namespaced: true,
        state,
        mutations,
        strict: debug
    } as Module<IUserFormState, IState>;
}

export default createModule();






























import Vue from 'vue';
import Component from 'vue-class-component';
import BaseCard from '../../BaseCard.vue';
import HierarchyConfigurator from './hierarchy-configurator.vue';
import store from '@/store';
import * as Logger from 'js-logger';
import {get} from 'lodash-es';
import QuizHierarchy from '@/model/quizHierarchy';
import {HierarchyLevel} from '@/model/hierarchy';
import SubCheckWrapper from '@/components/sub-check-wrapper';
import {State} from 'vuex-class';
import {IPreconfiguredQuizHierarchy} from '@/model/quizHierarchy/preconfiguration';
import {Prop} from 'vue-property-decorator';
import {IServerConfig} from '@/model/serverConfig';
import {User} from '@/model/user';
import {ApiCallFailed} from '@/util/apicallfailed';
import {APIWrapper} from '@/util/api';
import {IHierarchyState} from '@/store/modules/hierarchy';
import {Store} from 'vuex';
import {IState} from '@/store/state';

const log = Logger.get('test-configure');

export class FetchQuizHierarchyError extends Error {
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
    this.name = FetchQuizHierarchyError.name;
  }
}

export async function fetchQuizHierarchy($api: APIWrapper, $store: Store<IState>, hierarchyState: IHierarchyState,
                                         sectionSelected: number | null) {
  log.debug('Checking hierarchy state.');

  if (hierarchyState.areaCategorySelected === undefined ||
    hierarchyState.areaSelected === undefined ||
    hierarchyState.subjectSelected === undefined) {
    log.error('Invalid hierarchy detected: ', hierarchyState);
    throw new FetchQuizHierarchyError('Error fetching your quiz, sorry.');
  }

  let response;

  if (!sectionSelected) {
    response = await $store.state.axios.get(
      `/api/test/quiz/take/${hierarchyState.areaCategorySelected.id}` +
      `/${hierarchyState.areaSelected.id}` +
      `/${hierarchyState.subjectSelected.id}` +
      `/${hierarchyState?.topicSubtopicSelected?.topicId}` +
      `/${hierarchyState?.topicSubtopicSelected?.subtopicId}`);
  } else {
    response = await $store.state.axios.get(
      `/api/test/quiz/take/${hierarchyState.areaCategorySelected.id}` +
      `/${hierarchyState.areaSelected.id}` +
      `/${hierarchyState.subjectSelected.id}` +
      `/${hierarchyState?.topicSubtopicSelected?.topicId}` +
      `/${hierarchyState?.topicSubtopicSelected?.subtopicId}` +
      `/${sectionSelected}`);
  }

  return response.data;
}

export function updateUserQuizConfig(hierarchyState: IHierarchyState, $store: Store<IState>, isRandom: boolean) {
  const userUpdate = {
    options: {
      QUIZ_CONFIG: {
        QCFG_CAT_ID: hierarchyState?.areaCategorySelected?.id,
        QCFG_AREA_ID: hierarchyState?.areaSelected?.id,
        QCFG_SUBJ_ID: hierarchyState?.subjectSelected?.id,
        QCFG_TOP_SUBT_ID: hierarchyState?.topicSubtopicSelected?.id,
        QCFG_SECTION_ID: hierarchyState.sectionSelected,
        IS_RANDOM: isRandom
      }
    }
  };

  $store.commit('user/updateUserAttributes', userUpdate);
}

@Component({
  components: {
    BaseCard,
    SubCheckWrapper,
    HierarchyConfigurator
  }
})
export default class Configure extends Vue {
  fetchingQuizHierarchy = false;
  errorMsg = '';
  isRandom = false;
  areaOfStudy = '';

  @State(state => get(state.user.options, 'QUIZ_CONFIG'))
  preconfiguredQuizHierarchy: IPreconfiguredQuizHierarchy;

  @Prop({default: () => store.state.serverConfig})
  serverConfig: IServerConfig;

  @Prop({default: () => new User(store.state.user)})
  user: User;

  get IsValidConfig() {
    return !store.state.hierarchy.hierarchyUpdating;
  }

  get hierarchyLevel() {
    return this.isRandom ? HierarchyLevel.Subject : HierarchyLevel.TopicSubtopic;
  }

  get anyLevel() {
    return this.isRandom ? HierarchyLevel.Area : null;
  }

  mounted() {
    this.isRandom = this.user.hasSubscription() && this.preconfiguredQuizHierarchy.IS_RANDOM;
    this.areaOfStudy = this.$route.params.areaOfStudy;
  }

  async fetchRandomQuizHierarchy() {
    this.fetchingQuizHierarchy = true;
    try {
      log.debug('Checking rand hierarchy state.');
      const hierarchyState = store.state.hierarchy;
      if (hierarchyState.areaCategorySelected == null ||
          hierarchyState.areaSelected == null ||
          hierarchyState.subjectSelected == null) {
        log.error('Invalid hierarchy detected: ', hierarchyState);
        this.errorMsg = 'Error fetching your quiz, sorry.';
        return;
      }
      const res = await this.$api.get(
          `/api/test/quiz/take/random/${hierarchyState.areaCategorySelected.id}` +
          `/${hierarchyState.areaSelected.id}` +
          `/${hierarchyState.subjectSelected.id}`);
      const quizHierarchy = res.QuizHierarchy;

      this.updateUserQuizConfig();
      this.$store.commit('takeTest/updateQuizHierarchy',
                         QuizHierarchy.fromSSQuizHierarchy(quizHierarchy));

      this.$store.commit('user/updateUserAttributes', {
        todaysTestCount: this.user.todaysTestCount + 1
      });

      log.debug('pushing route dashboard-study-take-test');
      this.$router.push({name: 'dashboard-study-take-test'});
    } catch (e) {
      log.error('Unexpected exception', e);
      this.errorMsg = 'An unexpected error occurred please try later.';

      if (e instanceof ApiCallFailed) {
          log.info('Too many tests taken.', e.statusDict.error_msg);
          this.errorMsg = e.statusDict.error_msg;
      }
    } finally {
      this.fetchingQuizHierarchy = false;
    }
  }

  async takeQuiz() {
    if (this.isRandom) {
      return this.fetchRandomQuizHierarchy();
    }

    this.fetchingQuizHierarchy = true;

    // int areaCategoryId, int areaId, int subjectId, int topicId, int subTopicId, int? section = null
    try {
      const hierarchyState = store.state.hierarchy;

      let sectionSelected = (hierarchyState.sectionSelected === undefined) ? null
        : hierarchyState.sectionSelected;

      log.debug('/api/test/quiz/take with',
                [hierarchyState?.areaCategorySelected?.id,
                 hierarchyState?.areaSelected?.id,
                 hierarchyState?.subjectSelected?.id,
                 hierarchyState?.topicSubtopicSelected?.topicId,
                 hierarchyState?.topicSubtopicSelected?.subtopicId,
                 sectionSelected]);

      const res = await fetchQuizHierarchy(this.$api, this.$store, hierarchyState, sectionSelected);

      if (res.status === 0) {
        log.error(res);
        this.errorMsg = res.error_msg || 'Error occurred fetching your quiz.';
        return;
      }

      if (res.value.too_many_tests) {
        log.info('Too many tests taken.');
        this.errorMsg = 'Sorry you have taken too many tests.';
        return;
      }
      updateUserQuizConfig(hierarchyState, this.$store, this.isRandom);
      this.$store.commit('takeTest/updateQuizHierarchy',
                         QuizHierarchy.fromSSQuizHierarchy(res.value.QuizHierarchy));

      this.$store.commit('user/updateUserAttributes', {
        todaysTestCount: this.user.todaysTestCount + 1
      });
      log.debug('pushing route dashboard-study-take-test');
      await this.$router.push({name: 'dashboard-study-take-test'});
    } catch (e) {
      log.error('Unexpected exception', e);
      this.errorMsg = 'An unexpected error occurred please try later.';
    } finally {
      this.fetchingQuizHierarchy = false;
    }
  }

  updateUserQuizConfig() {
    const hierarchyState = this.$store.state.hierarchy;

    const userUpdate = {
      options: {
        QUIZ_CONFIG: {
          QCFG_CAT_ID: hierarchyState.areaCategorySelected.id,
          QCFG_AREA_ID: hierarchyState.areaSelected.id,
          QCFG_SUBJ_ID: hierarchyState.subjectSelected.id,
          QCFG_TOP_SUBT_ID: hierarchyState.topicSubtopicSelected.id,
          QCFG_SECTION_ID: hierarchyState.sectionSelected,
          IS_RANDOM: this.isRandom
        }
      }
    };

    this.$store.commit('user/updateUserAttributes', userUpdate);
  }
}

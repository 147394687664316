








































import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import {User} from '@/model/user';
import {State} from 'vuex-class';
import * as Logger from 'js-logger';
import {IServerConfig, ISubscriptionDescription} from '@/model/serverConfig';
import {IStatusDictionary} from '@/util/apiresponse';
import {ApiCallFailed} from '@/util/apicallfailed';

const log = Logger.get('views-store-finalexam-buy');

interface IItem {
  purchaseType: string;
  numTokens: number | string;
  cost: number;
  returnUrl: string;
  cancelUrl: string;
}

interface ICreatePaymentResult {
  id: string;
  approval_url: string;
}


@Component
export default class BuyExamTokens extends Vue {

  @State('user')
  user: User;

  @State('serverConfig')
  serverConfig: IServerConfig;

  amPreparingPayment = false;

  errorMessage = '';

  items: IItem[] = [];

  headers: any[] = [
    { text: 'Number Of Tokens', value: 'numTokens'},
    { text: 'Cost (USD)', value: 'cost' },
    { text: 'Purchase', value: 'cost' }
  ];

  created() {

    const baseUrl = location.protocol + '//' + location.host + '/';

    const cancelUrl = baseUrl + '#' + this.$route.path;
    const returnUrl = baseUrl + this.$router.resolve({
      name: 'store-confirm-purchase'
    }).href;

    this.items.push({
      purchaseType: 'Single Token',
      numTokens: 1,
      cost: 2.00,
      returnUrl,
      cancelUrl
    });

    this.items.push({
      purchaseType: 'Four Tokens',
      numTokens: 4,
      cost: 4.00,
      returnUrl,
      cancelUrl
    });

    [this.serverConfig.sub_silver, this.serverConfig.sub_gold,
     this.serverConfig.sub_platinum].forEach(
       (sub: ISubscriptionDescription) => {
          this.items.push({
            purchaseType: sub.name,
            numTokens: sub.examTokens < 100 ? sub.examTokens : 'Unlimited',
            cost: sub.price
          } as IItem);
        });
  }

  async buy(item: IItem) {
    this.amPreparingPayment = true;
    try {
      log.info('Creating payment.');

      let res = await this.$api.post('/api/store/final-exam-token/create-payment',
        {
          num_tokens: item.numTokens,
          return_url: item.returnUrl,
          cancel_url: item.cancelUrl
        }) as ICreatePaymentResult;

      log.info('Got result', res);
      window.location.href = res.approval_url;
    } catch (e) {
      if (e instanceof ApiCallFailed) {
        log.debug('API Call Failed', e.message);
        log.debug('API Call failed status dict:', e.statusDict);
      }

      this.errorMessage = 'Unexpected error, please try later.';
      log.error('Error setting up payment', e);
      this.amPreparingPayment = false;
    } finally {
      this.amPreparingPayment = false;
    }
  }
}










import {Component, Mixins, Prop} from 'vue-property-decorator';
import ScoreChart from '@/components/score/score-chart.vue';
import BaseCard from '../BaseCard.vue';
import BaseCardMixin from '@/components/dashboard/BaseCardMixin';
import {User} from '@/model/user';
import store from '@/store';

  // Maximum number of scores to display.
const MAX_SCORES = 5;

@Component({
  components: {
    BaseCard,
    ScoreChart
  }
})
export default class ScoreHistory extends Mixins(BaseCardMixin) {
  averages: number;
  chartData: Array<[number, number]> = [];
  @Prop({ default: () => new User(store.state.user)}) user: User;
}

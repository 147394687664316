import { Module, MutationTree, Mutation } from 'vuex';
import QuizHierarchy from '@/model/quizHierarchy';
import Question from '@/model/quizHierarchy/question';
import { IState } from '../state';
import Score from '@/model/quizHierarchy/score';

const debug = process.env.NODE_ENV !== 'production';

export interface ITakeTestState {
  quizHierarchy: QuizHierarchy;
  missedQuestions: Question[];
  score: number;
}

export const DefaultTakeTestState = {
  quizHierarchy: new QuizHierarchy(),
  missedQuestions: [],
  score: 100
} as ITakeTestState;

// mutations
interface ITakeTestMutation<S> extends MutationTree<S> {
  updateQuizHierarchy: Mutation<S>;
  addMissedQuestion: Mutation<S>;
  resetMissedQuestions: Mutation<S>;
  updateScore: Mutation<S>;
  incrementMissedQuestionTrackerStat: Mutation<S>;
  incrementCorrectQuestionTrackerStat: Mutation<S>;
  addScore: Mutation<S>;
}

const mutations: ITakeTestMutation<ITakeTestState> = {
  // commit('takeTest/updateQuizHierarchy')
  updateQuizHierarchy(state: ITakeTestState, quizHierarchy: QuizHierarchy) {
    state.quizHierarchy = quizHierarchy;
  },
  addMissedQuestion(state: ITakeTestState, missedQuestion: Question) {
    state.missedQuestions.push(missedQuestion);
  },
  resetMissedQuestions(state: ITakeTestState) {
    state.missedQuestions.splice(0, state.missedQuestions.length);
  },
  updateScore(state: ITakeTestState, score: number) {
    state.score = score;
  },
  incrementMissedQuestionTrackerStat(state: ITakeTestState, question: Question) {
    question.UserQuestionTracker.MissCount++;
    question.SysQuestionTracker.MissCount++;
  },
  incrementCorrectQuestionTrackerStat(state: ITakeTestState, question: Question) {
    question.UserQuestionTracker.CorrectCount++;
    question.SysQuestionTracker.CorrectCount++;
  },
  addScore(state: ITakeTestState, score: Score) {
    state.quizHierarchy.PreviousScores.push(score);
  }
};

export function createModule(state: ITakeTestState | null = null): Module<ITakeTestState, IState> {
  if (state == null) {
    state = DefaultTakeTestState;
  }

  return {
    namespaced: true,
    state,
    mutations,
    strict: debug
  } as Module<ITakeTestState, IState>;
}

export default createModule(null);

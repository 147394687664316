


















import Vue from 'vue';
import ReportBugDlg from '@/components/report-bug-dlg.vue';
import SubNeededBottomSheet from '@/components/sub-needed-bottom-sheet.vue';
import AppNavDrawer from './AppNavDrawer.vue';
import AppBar from './AppBar.vue';
import ChatBox from '@/components/dashboard/chat/box.vue';

const Component = Vue.extend({
  name: 'app',
  components: { ReportBugDlg, AppNavDrawer, AppBar, ChatBox, SubNeededBottomSheet },
  computed: {
    showReportBugDialog(): boolean {
      return this.$store.state.showReportBugDlg;
    },
    amLoggedIn(): boolean {
      return this.$store.state.jwtToken !== '';
    },
    showMe() {
      return true;
    }
  }
});

export default Component;

import { render, staticRenderFns } from "./comment.vue?vue&type=template&id=2da9ddcd&scoped=true&lang=pug&"
import script from "./comment.vue?vue&type=script&lang=ts&"
export * from "./comment.vue?vue&type=script&lang=ts&"
import style0 from "./comment.vue?vue&type=style&index=0&id=2da9ddcd&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2da9ddcd",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAvatar,VBtn,VCard,VCardText,VFlex,VLayout})

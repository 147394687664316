















import Vue from 'vue';
import { Prop, Watch } from 'vue-property-decorator';
import Component from 'vue-class-component';

// Maximum number of scores to display.
const MAX_SCORES = 5;

// Hierarchy scores use CamelCasing, should really standardize this.
interface IHierarchyUserScore {
  Score: number;
  Date: string;
}

@Component
export default class ScoreChart extends Vue {
  @Prop({default: () => []}) scores: IHierarchyUserScore[];
  @Prop({default: true}) chartOnly: boolean;
  @Prop({default: MAX_SCORES}) scoresToShow: number;
  @Prop({default: false}) embeddedInScoresView: boolean;
  @Prop({default: ''}) height: string;

  averages: number;
  chartData: Array<[string, number]> = [];

  @Watch('scores')
  onScoresChanged(newVal: IHierarchyUserScore[]) {
    this.updateChartData(newVal);
  }

  updateChartData(scores: IHierarchyUserScore[]) {
    let newChartData: Array<[string, number]>  = [];
    let totalScores = 0;
    scores.forEach((score: IHierarchyUserScore, n: number) => {
      if (n < this.scoresToShow) {
        newChartData.push([score.Date, score.Score]);
      }
      totalScores += score.Score;
    });

    this.averages = Math.round(totalScores / scores.length);
    this.chartData = newChartData;
  }

  mounted() {
    this.updateChartData(this.scores);
  }
}

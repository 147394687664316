


















import Component from 'vue-class-component';
import BaseCard from '../BaseCard.vue';
import IComment from '@/model/discuss/comment';
import Logger from 'js-logger';

const logger = Logger.get('cmp.discuss-question');

if (process.env.NODE_ENV !== 'production') {
    logger.setLevel(Logger.DEBUG);
}

@Component(
  {
    components: {
      BaseCard
    }
  }
)
export default class News extends BaseCard {

  replies: IComment[] = [];
  commentCount = 0;
  amBusy = true;

  async mounted() {
    try {
      this.replies = await this.$api.get('/api/discuss/question/latest-replies') as IComment[];
      this.commentCount = await this.$api.get('/api/discuss/question/reply-count') as number;
    } catch (e) {
      logger.error('Error fetching comment counts and replies.');
    }

    this.amBusy = false;
  }
}

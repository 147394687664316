// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
// import 'babel-polyfill';
import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import vuetify from './plugins/vuetify';
import VueSocketio from 'vue-socket.io-extended';
import io from 'socket.io-client';
import Chat from 'vue-beautiful-chat';
import './util/api';


// tslint:disable-next-line:no-var-requires
import 'vuetify/dist/vuetify.min.css';
import App from './App.vue';
import router from './router';
import store from './store';
import * as VeeValidate from 'vee-validate';
import './vuelibs';
import './plugins/fontawesome';
import './vuetify-overrides.css';

localStorage.debug = '';

// tslint:disable-next-line:no-var-requires
const promised = require('vue-promised');

// tslint:disable-next-line:no-var-requires
const Logger = require('js-logger');
Logger.useDefaults();
Logger.setLevel(Logger.DEBUG);

// When port is 8080, then assume that fsocket is running on port 5000...
// The socket is connected after a successful login.  See Login.vue.
const loc = window.location;
const socketUrl = loc.port === '8080' ? 'http://' + location.hostname + ':8000/' :
  `${loc.protocol}//${loc.host}:${loc.port}/`;
export const flaskSocketUrl = loc.port === '8080' ? 'http://localhost:5555/' :
  `${loc.protocol}//${loc.host}/sio/`;
const socket = io(socketUrl, {
  autoConnect: false
});

Vue.use(VueSocketio, socket, {store});

Vue.component('Promised', promised.Promised);
Vue.use(VeeValidate);
Vue.use(Chat);
Vue.config.productionTip = false;


new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

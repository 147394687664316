import {IRegistrationState, RegistrationState} from './modules/registration';
import {FormState as UserFormState, IUserFormState} from './modules/userform';
import {IServerConfig} from '@/model/serverConfig';
import {HierarchyState, IHierarchyState} from './modules/hierarchy';
import {IUserDictionaryModel, User} from '@/model/user';
import {DefaultTakeTestState as TakeTestState, ITakeTestState} from './modules/taketest';
import {INotificationState, NotificationState} from './modules/notification';
import {
  DefaultQuestionDiscussState as QuestionDiscussState,
  IQuestionDiscussState
} from '@/store/modules/question-discussion';
import axios, {AxiosInstance} from 'axios';

// Default url used when running locally.
let crossbarUrl = 'ws://127.0.0.1:8889/ws';

if (location.hostname === 'next.seasources.net') {
  crossbarUrl = 'wss://next.seasources.net:8889/ws';
} else if (location.protocol === 'https:') {
  crossbarUrl = 'wss://' + location.host + '/ws';
}

// Default url used when running locally.
let baseURL = location.protocol + '//localhost:8000/';

if (location.hostname === 'next.seasources.net') {
  baseURL = 'https://next.seasources.net/';
} else if (location.protocol === 'https:') {
  baseURL = 'https://' + location.host + '/';
}

export interface IState {
  appDrawer: boolean;
  crossbarUrl: string;
  axios: AxiosInstance;
  jwtToken: string;
  databaseUpdateTime: Date;
  hierarchy: IHierarchyState;
  registration: IRegistrationState;
  serverConfig: IServerConfig | null;
  user: IUserDictionaryModel;
  // permissions is a dictionary mapping actions to list of subscription
  // levels that have access.
  permissions: { [key: string]: string[] };
  userform: IUserFormState;
  takeTest: ITakeTestState;
  questionDiscuss: IQuestionDiscussState;
  chatEnabled: boolean;
  chatOpen: boolean;
  socketConnected: boolean;
  // When trueish, showReportBugDlg will show the report bug dialog.
  // If showReportBugDlg is an object, it is passed along with the bug
  // report.  If the object contains the property dialogPrefills, then
  // dialogPrefills is used to prefill the report bug dialog.  The
  // properties description and subject are used to fill the dialog's
  // matching properties.
  showReportBugDlg: any | null;
  showSubscriptionBottomSheet: boolean;
  notification: INotificationState;
  sampleStudyguideUrl: string;
}

export function createState(): IState {
  return {
    appDrawer: false,
    crossbarUrl,
    axios: axios.create({baseURL}),
    jwtToken: '',
    databaseUpdateTime: new Date(),
    hierarchy: HierarchyState,
    registration: RegistrationState,
    takeTest: TakeTestState,
    permissions: {},
    // Set during login
    serverConfig: null,
    user: new User(null),
    userform: UserFormState,
    questionDiscuss: QuestionDiscussState,
    socketConnected: false,
    chatEnabled: true,
    chatOpen: false,
    showReportBugDlg: null,
    notification: NotificationState,
    showSubscriptionBottomSheet: false,
    sampleStudyguideUrl: 'https://quizzes.seasources.net/downloads/studyguide.zip'
  } as IState;
}

export default createState();

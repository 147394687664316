































import jsLogger from 'js-logger';
import * as Logger from 'js-logger';
import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import BaseCard from '@/components/dashboard/BaseCard.vue';
import {State} from 'vuex-class';
import {IServerConfig} from '@/model/serverConfig';
import IUserModel, {IUserDictionaryModel} from '@/model/user';
const logger = Logger.get('cmp.learnmore');

if (process.env.NODE_ENV !== 'production') {
  logger.setLevel(jsLogger.DEBUG);
}

@Component(
  {
    components:
      {
        BaseCard
      }
  }
)
export default class LearnMore extends Vue {
  @State('serverConfig')
  serverConfig: IServerConfig;

  @State('sampleStudyguideUrl')
  sampleStudyguideUrl: string;

  @State('user')
  user: IUserDictionaryModel;

  get areaStudyguidePrice() {
    return this.serverConfig.studyguide_price_area;
  }

  get subjectStudyguidePrice() {
    return this.serverConfig.studyguide_price_subject;
  }

  get hasTokens() {
    return this.$store.state.jwtToken && this.user.studyguideTokens > 0;
  }
}

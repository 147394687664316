
















import jsLogger from 'js-logger';
import * as Logger from 'js-logger';
import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

const logger = Logger.get('cmp.store-card');

if (process.env.NODE_ENV !== 'production') {
    logger.setLevel(jsLogger.DEBUG);
}

@Component
export default class StoreCard extends Vue {
  @Prop({required: true})
  title: string;

  @Prop({required: true})
  icon: string;

  @Prop({default: 'red'})
  iconColor: string;
}

















import jsLogger from 'js-logger';
import * as Logger from 'js-logger';
import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import DiscussQuestionCmp from '@/components/discuss/discuss-question.vue';
import {IStatusDictionary} from '@/util/apiresponse';
import {Mutation} from 'vuex-class';
import Question from '@/model/quizHierarchy/question';

const logger = Logger.get('view.discuss-question');

if (process.env.NODE_ENV !== 'production') {
  logger.setLevel(jsLogger.DEBUG);
}

@Component({
  components: { DiscussQuestionCmp }
})
export default class DiscussQuestion extends Vue {

  isBusy: boolean = true;
  errorMessage: string = '';

  @Mutation('questionDiscuss/setQuestion') setQuestionDiscussQuestion: (question: Question) => void;

  async mounted() {
    try {
      logger.info(`Fetching question discussion ${this.$route.params.qid}`);
      let res = await this.$store.state.axios.get(
          `/api/discuss/question/${this.$route.params.qid}`);
      res = res.data as IStatusDictionary;
      if (!res.status) {
        this.errorMessage = res.error_msg;
      } else {
        logger.debug('Setting question', res.value);
        this.setQuestionDiscussQuestion(res.value);
      }
    } catch (e) {
      this.errorMessage = 'Unexpected error fetching this discussion.  Please try later.';
      logger.error(e);
    } finally {
      this.isBusy = false;
    }
  }
}

import {StudyguideDownload, StudyguideCreationStatus,
    QueuedStudyguideCreation} from '@/model/notification';
import { Module, Mutation, MutationTree } from 'vuex';
import { IState } from '@/store/state';
const debug = process.env.NODE_ENV !== 'production';

export interface INotificationState {
    studyguideDownloads: StudyguideDownload[];
    queuedStudyguideCreation: QueuedStudyguideCreation | null;
}

export const NotificationState: INotificationState = {
    studyguideDownloads: [],
    queuedStudyguideCreation: null
};

interface INotificationStateMutation<S> extends MutationTree<S> {
    setQueuedStudyguideCreation: Mutation<S>;
    addQueuedStudyguideDownload: Mutation<S>;
}

const mutations: INotificationStateMutation<INotificationState> = {
    setQueuedStudyguideCreation(state: INotificationState,
                                queuedStudyguideCreation: QueuedStudyguideCreation) {
        state.queuedStudyguideCreation = queuedStudyguideCreation;
    },

    addQueuedStudyguideDownload(state: INotificationState,
                                studyguideDownload: StudyguideDownload) {
        state.studyguideDownloads.push(studyguideDownload);
    }
};

export function createModule(state: INotificationState | null = null):
        Module<INotificationState, IState> {
    if (state == null) {
        state = Object.assign(NotificationState, {});
    }

    return {
        namespaced: true,
        state,
        mutations,
        strict: debug
    } as Module<INotificationState, IState>;
}

export default createModule();
















































import Vue from 'vue';
import {State} from 'vuex-class';
import {Component} from 'vue-property-decorator';
import {ITakeTestState} from '@/store/modules/taketest';
import Question from '@/model/quizHierarchy/question';
import Answer from '@/model/quizHierarchy/answer';
import {IStatusDictionary} from '@/util/apiresponse';
import {IUserDictionaryModel} from '@/model/user';
import QuestionTrackerView from '@/components/dashboard/study/test/question-tracker.vue';
import {sum, difference, cloneDeep} from 'lodash-es';
import {fetchQuizHierarchy, updateUserQuizConfig} from "@/components/dashboard/study/test/configure.vue";
import store from "@/store";
import QuizHierarchy from "@/model/quizHierarchy";
import getLogger from '../../logger';

let Logger = getLogger();


enum PostingDataStatus {
  NotPosting,
  PostingScore,
  PostingQuestionTrackingStats,
  FetchingQuiz
}

@Component(
  {
    components: {
      QuestionTrackerView
    }
  }
)
export default class TestResults extends Vue {
  @State('takeTest') takeTest: ITakeTestState;
  @State('user') user: IUserDictionaryModel;

  postingData: PostingDataStatus = PostingDataStatus.NotPosting;
  postingDataErrorMsg: string | null = null;

  async mounted() {
    const qh = this.takeTest.quizHierarchy;
    if (!qh.IsRetakeOfMissedQuestions) {
      this.postScores();
    }

    this.postQuestionStats();
  }

  async postScores() {
    try {
      const qh = this.takeTest.quizHierarchy;
      Logger.debug('Posting scores for ', qh);

      let res = await this.$store.state.axios.post('api/test/score', {
        score: this.takeTest.score,
        area_category: qh.AreaCategory.Id,
        area: qh.Area.Id,
        subject: qh.Subject.Id,
        topic: qh.Topic.Id,
        subtopic: qh.Subtopic.Id,
        final_exam: qh.FinalExamId
      });
      res = res.data as IStatusDictionary;

      Logger.debug('Post score respone: ', res);
      if (res.error_msg) {
        this.postingDataErrorMsg = res.error_msg;
      }
    } catch (e) {
      Logger.error('Error posting score: ', e);
    } finally {
      this.postingData = PostingDataStatus.NotPosting;
    }
  }

  async nextSection() {
    try {
      this.postingData = PostingDataStatus.FetchingQuiz;

      let nextSection = this.takeTest.quizHierarchy.Section;
      if (nextSection === undefined) {
        this.postingDataErrorMsg = "Sorry, the next section is not currently available";
        return;
      } else {
        nextSection++;
      }

      const hierarchyState = store.state.hierarchy;
      Logger.info("Taking next section - ", this.takeTest.quizHierarchy.Section);
      const res = await fetchQuizHierarchy(this.$api, this.$store, hierarchyState, nextSection);

      if (res.status === 0) {
        Logger.error(res);
        this.postingDataErrorMsg = res.error_msg || 'Error occurred fetching your quiz.';
        return;
      }

      if (res.value.too_many_tests) {
        Logger.info('Too many tests taken.');
        this.postingDataErrorMsg = 'Sorry you have taken too many tests.';
        return;
      }
      updateUserQuizConfig(hierarchyState, this.$store, false);
      this.$store.commit('takeTest/updateQuizHierarchy',
        QuizHierarchy.fromSSQuizHierarchy(res.value.QuizHierarchy));

      this.$store.commit('user/updateUserAttributes', {
        todaysTestCount: this.user.todaysTestCount + 1
      });
      Logger.debug('pushing route dashboard-study-take-test');
      await this.$router.push({name: 'dashboard-study-take-test'});

    } catch (e) {
      Logger.error("Unexpected error fetching quiz.");
      this.postingDataErrorMsg = 'Unexpected error fetching quiz.';
    } finally {
      this.postingData = PostingDataStatus.NotPosting;
    }

  }

  async postQuestionStats() {
    const correctQuestions = difference(this.takeTest.quizHierarchy.Questions,
                                        this.takeTest.missedQuestions);
    const correctIds = correctQuestions.map((q) => q.Id);
    const incorrectIds = this.takeTest.missedQuestions.map((q) => q.Id);
    try {
      let res = await this.$store.state.axios.post('api/test/quiz/log-question-stats',
        {
          missed_question_ids: incorrectIds,
          correct_question_ids: correctIds
        }
      );
      res = res.data as IStatusDictionary;

      Logger.debug('log_question_stats response: ', res);
      if (res.error_msg) {
          this.postingDataErrorMsg = res.error_msg;
      }
    } catch (e) {
      Logger.error('Error posting question stats: ', e);
    } finally {
      this.postingData = PostingDataStatus.NotPosting;
    }
  }

  configureAnotherTest() {
    this.$router.push({name: 'dashboard-study-configure'});
  }

  retakeTest() {
    this.$router.push({name: 'dashboard-study-take-test'});
  }

  takeMissedQuestionsTest() {
    const newQuizHierarchy = cloneDeep(this.takeTest.quizHierarchy);
    newQuizHierarchy.Questions = this.missedQuestions.slice(0);
    newQuizHierarchy.IsRetakeOfMissedQuestions = true;
    this.$store.commit('takeTest/updateQuizHierarchy', newQuizHierarchy);
    this.retakeTest();
  }

  get scores() {
    return this.takeTest.quizHierarchy.PreviousScores;
  }

  get userName() {
    return this.user.firstName;
  }

  get testScore() {
    return this.takeTest.score;
  }

  get timesTaken() {
    return this.scores.length;
  }

  get averageScore() {
    // comment.
    return Math.round(sum(this.scores.map((score) => score.Score)) / this.scores.length);
  }

  get missedQuestions() {
    return this.takeTest.missedQuestions;
  }

  correctAnswer(question: Question) {
    const correctAnswer = question.Answers.find((answer) => answer.IsCorrect) as Answer;

    if (correctAnswer == null) {
      return 'No correct answer specified for this question.  This is a bug.';
    }
    return correctAnswer.AnswerText;
  }

  get postingMessage() {
    if (this.postingData === PostingDataStatus.PostingScore) {
      return 'Posting your score, please wait...';
    } else if (this.postingData === PostingDataStatus.PostingQuestionTrackingStats) {
      return 'Posting your answer results, please wait...';
    } else if (this.postingData === PostingDataStatus.FetchingQuiz) {
      return 'Downloading your quiz configuration, please wait...';
    }
  }

  get amPosting() {
    return this.postingData !== PostingDataStatus.NotPosting;
  }

  get hasMoreSections() {
    return this.takeTest.quizHierarchy.NextSection;
  }
}

























import jsLogger from 'js-logger';
import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import {chatToBeautifulMessage, IBeautifulMessage, IChat} from '@/model/chat';
import {IStatusDictionary, Status} from '@/util/apiresponse';
import './chat-overrides.css';
import {Mutation, State} from 'vuex-class';
import {User} from '@/model/user';

const logger = jsLogger.get('cmp.box');

if (process.env.NODE_ENV !== 'production') {
  logger.setLevel(jsLogger.DEBUG);
}

@Component
export default class Box extends Vue {

  @State('chatOpen')
  chatOpen: boolean;

  @State('chatEnabled')
  chatEnabled: boolean;

  @State('socketConnected')
  socketConnected: boolean;

  @State('user')
  user: User;

  @Mutation('updateChatEnabled')
  updateChatEnabled: (chatEnabled: boolean) => void;

  @Mutation('updateChatOpen')
  updateChatOpen: (chatOpen: boolean) => void;

  // snackbar is used for showing errors.
  snackbarShow = false;
  snackbarTimeout = 5000;
  snackbarText_ = '';

  messageList: IBeautifulMessage[] = [];

  newMessagesCount = 0;

  participants = [];

  colors = {
    header: {
      bg: '#4e8cff',
      text: '#ffffff'
    },
    launcher: {
      bg: '#4e8cff'
    },
    messageList: {
      bg: '#ffffff'
    },
    sentMessage: {
      bg: '#4e8cff',
      text: '#ffffff'
    },
    receivedMessage: {
      bg: '#eaeaea',
      text: '#222222'
    },
    userInput: {
      bg: '#f4f7f9',
      text: '#565867'
    }
  };

  async mounted() {
    this.messageList = await this.fetchLatestChats();
    this.$socket.removeListener('chat-message');
    this.$socket.on('chat-message', (message: IBeautifulMessage) => {
      if (message.author === this.user.chatName) {
        message.author = 'me';
      }
      this.messageList.push(message);

      if (!this.isChatOpen) {
        this.newMessagesCount++;
      }
    });
  }

  openChat() {
    this.isChatOpen = true;
    this.newMessagesCount = 0;
  }

  closeChat() {
    this.isChatOpen = false;
  }

  // Called when the user sends a message.
  async onMessageWasSent(message) {
    let res = await this.$store.state.axios.post('/api/chat/new', {text: message.data.text});
    res = res.data as IStatusDictionary;
    if (res.status === Status.Fail) {
      logger.error('Error submitting message', res.error_msg);
      this.snackbarText = 'Error submitting your message.';
    } else {
      message = chatToBeautifulMessage(res.value, '');
      this.$socket.emit('chat-message', message);
    }
  }

  async fetchLatestChats(): Promise<IBeautifulMessage[]> {
    let res = await this.$store.state.axios.get('/api/chat/last/100');
    res = res.data as IStatusDictionary;
    if (res.status === Status.Fail) {
      throw new Error(res.error_msg);
    }

    logger.debug('Fetched last 100 chats', res);

    return (res.value as IChat[]).map<IBeautifulMessage>((chat) => chatToBeautifulMessage(chat, this.user.chatName));
  }

  get snackbarText() {
    return this.snackbarText_;
  }

  set snackbarText(val) {
    this.snackbarShow = true;
    this.snackbarText_ = val;
  }

  get isChatOpen() {
    return this.chatOpen;
  }

  set isChatOpen(chatOpen: boolean) {
    this.updateChatOpen(chatOpen);
  }
}

//
// Models used for the notification section of the site.
//

export class StudyguideDownload {
    name: string;
    downloadUrl: string;
    graphicBookUrl: string;
}

export enum StudyguideCreationStatus {
    SG_STATUS_QUEUED = 'QUEUED',
    SG_STATUS_CREATING = 'CREATING',
    SG_STATUS_ERROR = 'ERROR',
    SG_STATUS_FINISHED = 'FINISHED'
}

/* tslint:disable */
export class QueuedStudyguideCreation {
    status: StudyguideCreationStatus;
    studyguide_url: string | null;
    graphicbook_url: string | null;
    start_time: string;
    start_time_formatted: string;
    end_time: number | null;
    end_time_formatted: string | null;
    errors: string | null;
}
/* tslint:enable */












import jsLogger from 'js-logger';
import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import {ISearchResult} from '@/model/search';

const logger = jsLogger.get('cmp.paginator');

if (process.env.NODE_ENV !== 'production') {
  logger.setLevel(jsLogger.DEBUG);
}

// Represents an item that is pageable.
export interface IPageable {
  currentPage: number;
  numPages: number;
}

@Component
export default
class Paginator extends Vue {
  // The item to be paged.
  @Prop({required: true})
  pageable: IPageable;

  // Query string that generates this pageable.  This is passed to routeName
  // if specified.
  @Prop({default: ''})
  query: string;

  @Prop({required: true})
  routeName: string;

  switchPage(pageNum: number) {
    logger.debug('switch page', pageNum);
    const params = {
      page: String(pageNum),
      query: this.query
    };
    if (!this.query) {
      delete params.query;
    }
    this.$router.push({
      name: this.routeName,
      params
    });
  }

  get PageNum(): number {
    return  this.pageable.currentPage;
  }

  get NumPages(): number {
    return this.pageable.numPages;
  }

  *pageRange() {
    // The page range should attempt to have 5 "previous" pages to the left.
    // Given a 20 page set, and the user is on page 3, the numbers returned should be
    // 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11

    // A 20 page set on page 8
    // 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
    let numbersWanted = 11;
    const BUFFER = 5;

    let start = Math.max(this.PageNum - BUFFER, 1);

    for (let x = start; numbersWanted && x <= this.NumPages; x++, numbersWanted--) {
      yield x;
    }
  }
}

/**
 * Most API calls return a status dictionary.
 *
 * The dictionary always contains a status attribute.  status is either
 * 1 for success or 0 for failure.  If a failure occurs, then the error_msg
 * attribute will be set which is a string containing error information.
 *
 * Sample Dictionaries
 * -------------------
 *
 * {
 *   'status': 1,
 *   'additional_info': 'Arbitrary info can be returned in a success call.'
 * }
 *
 * {
 *   'status': 0,
 *   'error_msg': 'Something went bad',
 *   'additional_info': 'Arbitrary info can be returned in a failed call.'
 * }
 *
 */

 export enum Status {
     Fail = 0,
     Success = 1
 }

 export interface IStatusDictionary {
    status: number;
    error_msg?: string;
    unhandled_exception?: boolean;
    [key: string]: any;
 }

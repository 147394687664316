











import jsLogger from 'js-logger';
import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

const logger = jsLogger.get('cmp.assets-tab');

if (process.env.NODE_ENV !== 'production') {
    logger.setLevel(jsLogger.DEBUG);
}

@Component
export default class AssetsTab extends Vue {
  assetLoading = false;
  activeAsset = '';

  @Prop({required: true})
  assets;

  mounted() {
    const _global = window as any;
    _global.onAssetLoad = () => this.assetLoading = false;
  }

  showAsset(asset: string) {
    logger.debug('showing asset: ', asset);
    const previousActiveAsset = this.activeAsset;
    this.activeAsset = asset;
    this.assetLoading = true;

    if (previousActiveAsset !== '') {
      const element = document.getElementById('pdfIframe') as HTMLIFrameElement;
      if (element != null) {
        element!.contentDocument!.location!.reload();
      }
    }
  }
}

























import Vue from 'vue';
import Component from 'vue-class-component';
import {Mutation, State} from 'vuex-class';

@Component
export default class SubNeededBottomSheet extends Vue {

  @State('showSubscriptionBottomSheet')
  showSubscriptionBottomSheet: boolean;

  @Mutation('updateShowSubscriptionBottomSheet')
  updateShowSubscriptionBottomSheet: (newStatus: boolean) => void;


  get showMe() {
    return this.showSubscriptionBottomSheet;
  }

  set showMe(val) {
    this.updateShowSubscriptionBottomSheet(val);
  }
}

import {capitalize} from 'lodash-es';

export interface IUserScore {
    Score: number;
    Date: string;
}

interface IUserModel {
    [key: string]: any;
    firstName: string;
    middleName: string;
    lastName: string;
    emailAddress: string;
    password?: string;
    passwordValidate?: string;
    currentLicense: string;
    desiredLicense: string;
    comments: string;
}

// Represents the model when returned as a dictionary.
export interface IUserDictionaryModel extends IUserModel {
    id: number;
    finalExamTokens: number;
    finalExams: any[];
    roles: string[];
    scores: IUserScore[];
    scoreCount: number;
    studyguideTokens: number;
    subscriptionLevel: string;
    todaysTestCount: number;
    verified: boolean;
    ticket: string;
    chatName: string;
    options: any;
    bbHash: string;
}

export class User implements IUserDictionaryModel {
    firstName: string = '';
    middleName: string = '';
    lastName: string = '';
    emailAddress: string = '';
    password: string = '';
    passwordValidate: string = '';
    currentLicense: string = '';
    desiredLicense: string = '';
    comments: string = '';
    id: number = 0;
    finalExamTokens: number = 0;
    finalExams: any[] = [];
    roles: string[] = [];
    scores: IUserScore[] = [];
    scoreCount: number = 0;
    studyguideTokens: number = 0;
    subscriptionLevel: string = '';
    todaysTestCount: number = 0;
    verified: boolean = false;
    ticket: string = '';
    chatName = '';
    options: any = {};
    bbHash = '';

    constructor(model: IUserDictionaryModel | null) {
        if (model != null) {
            Object.assign(this, model);
        }
    }

    canTakeTests(testsPerDay: number): boolean {
        return this.todaysTestCount < testsPerDay || this.subscriptionLevel !== '';
    }

    get fullName(): string {
      return this.firstName + ' ' + this.lastName;
    }

    subscriptionAccess(requiredLevel: string) {
        return requiredLevel === this.subscriptionLevel;
    }

    hasSubscription(): boolean {
        return this.subscriptionLevel !== '';
    }

    get examTokenCount() {
      return this.finalExamTokens > 500 ? 'Unlimited' : this.finalExamTokens;
    }
}

export function ssDictToUserDict(ssdict: any): IUserDictionaryModel {
    let userDict = {} as IUserDictionaryModel;
    userDict.firstName = capitalize(ssdict.first_name);
    userDict.middleName = capitalize(ssdict.middle_name);
    userDict.lastName = capitalize(ssdict.last_name);
    userDict.emailAddress = ssdict.email_address;
    userDict.password = undefined;
    userDict.passwordValidate = undefined;
    userDict.currentLicense = '';
    userDict.desiredLicense = '';
    userDict.comments = '';
    userDict.id = ssdict.id;
    userDict.finalExamTokens = ssdict.final_exam_tokens;
    userDict.finalExams = ssdict.final_exams;
    userDict.roles = ssdict.roles;
    userDict.scoreCount = ssdict.score_count;
    userDict.studyguideTokens = ssdict.studyguide_tokens;
    userDict.subscriptionLevel = ssdict.subscription_level;
    userDict.todaysTestCount = ssdict.todays_test_cnt;
    userDict.verified = ssdict.verified;
    userDict.ticket = ssdict.ticket;
    userDict.scores = ssdict.scores;
    userDict.options = ssdict.options;
    userDict.chatName = ssdict.chat_name;
    userDict.bbHash = ssdict.bbHash;
    return userDict;
}

export default IUserModel;

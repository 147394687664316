













import {Component, Mixins} from 'vue-property-decorator';
import BaseCard from '@/components/dashboard/BaseCard.vue';
import BaseCardMixin from '@/components/dashboard/BaseCardMixin';
import {State} from 'vuex-class';

@Component({
  components: { BaseCard }
})
export default class StudyGuideInfoCard extends Mixins(BaseCardMixin) {

  @State('sampleStudyguideUrl')
  sampleStudyguideUrl: string;
}

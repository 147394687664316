













import { User } from '@/model/user';
import Vue from 'vue';
import Component from 'vue-class-component';
import TodaysTest from '@/components/dashboard/home/TodaysTest.vue';
import MakeFinalExamCard from '@/components/dashboard/study/test/card-mk-final-exam.vue';
import StudyguideInfoCard from './studyguide/sg-card-info.vue';

@Component({
  components: {TodaysTest, MakeFinalExamCard, StudyguideInfoCard}
})
export default class DashboardStudy extends Vue {
  user?: User;

  created() {
    this.user = new User(this.$store.state.user);
  }

  get noSubroute() {
    return this.$route.path === '/dashboard/study' || this.$route.path === '/dashboard/study/';
  }
}


























import Vue from 'vue';
import store from '@/store';
import { IHierarchyNode, TopicSubtopic, HierarchyLevel } from '@/model/hierarchy';
import { State } from 'vuex-class';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { IStatusDictionary } from '@/util/apiresponse';
import { IPreconfiguredQuizHierarchy } from '@/model/quizHierarchy/preconfiguration';

const DefaultPcHierarchy = {
  QCFG_CAT_ID: -1,
  QCFG_AREA_ID: -1,
  QCFG_SUBJ_ID: -1,
  QCFG_TOP_SUBT_ID: -1,
  QCFG_SECTION_ID: -1
} as IPreconfiguredQuizHierarchy;

@Component
export default class HierarchyConfigurator extends Vue {
  // Exporting the enum did not seem to work, so `export` it as a static prop.
  static readonly HierarchyLevel = HierarchyLevel;

  @Prop({default: () => DefaultPcHierarchy})
  preconfiguredHierarchy: IPreconfiguredQuizHierarchy;

  @State(state => state.hierarchy.areaCategories)
  areaCategories: IHierarchyNode[];

  @Prop({default: null}) psAreaId: number;
  @State(state => state.hierarchy.areas)
  areas: IHierarchyNode[];

  @State(state => state.hierarchy.subjects)
  subjects: IHierarchyNode[];

  @State(state => state.hierarchy.topicSubtopics)
  topicSubtopics: TopicSubtopic[];

  pSelectedSection: number | null = null;

  // The maximum number of hierarchy levels to display.
  @Prop({default: HierarchyLevel.TopicSubtopic}) maxHierarchyLevel: HierarchyLevel;

  // Level to allow `Any` to be displayed as an option.  Typically used for
  // random tests.  Must be Area level or deeper.  Will not work for area category.
  @Prop({default: null}) anyLevel: HierarchyLevel;

  // If true, do not show the quiz sections.
  @Prop({default: false}) hideSection: boolean;

  @State(state => state.hierarchy.hierarchyUpdating)
  hierarchyUpdating: boolean;

  @State(state => state.hierarchy.sections)
  sections: number[];

  errorMsgAreaCategories: string[] = [];
  errorMsgAreas: string[] = [];
  errorMsgSubjects: string[] = [];
  errorMsgTopicSubtopics: string[] = [];

  // Copy the pre-selection properties to local attributes.  These attributes
  // will be modified to prevent pre-selections from attempting to apply
  // themselves when the user changes the hierarchy.
  areaCategoryId?: number = undefined;
  areaId?: number = undefined;
  subjectId?: number = undefined;
  topicSubtopicId?: number = undefined;
  // not technically an id, but dont want to clash with selectedSection
  selectedSectionId?: number = undefined;

  // Set when the hierarchy changes.  Anything above topic/subtopic changing
  // marks the hierarchy as changed.
  created() {
    this.getAreaCategories();
    store.dispatch('hierarchy/setAnyLevel', this.anyLevel);
  }

  mounted() {
    store.commit('hierarchy/updatePreconfiguredQuizHierarchy', this.preconfiguredHierarchy);
  }

  @Watch('anyLevel')
  onAnyLevelChange(val: HierarchyLevel, oldVal: HierarchyLevel) {
    store.dispatch('hierarchy/setAnyLevel', val);
  }

  getAreaCategories() {
    store
      .dispatch('hierarchy/getAreaCategories', this.$route.params.areaOfStudyId)
      .then((val: IStatusDictionary) => {
        if (val.status !== 1) {
          this.errorMsgAreaCategories = [val.error_msg || 'Error fetching area categories'];
        }
      });
  }

  get selectedAreaCategory() {
    return this.$store.state.hierarchy.areaCategorySelected;
  }

  set selectedAreaCategory(val: number) {
    const areaCategory = this.areaCategories.find((ac) => ac.id === val);

    this.$store.commit('hierarchy/updateAreaCategorySelected', areaCategory);
    this.$store.dispatch('hierarchy/getAreas', areaCategory!.id);
  }

  get selectedArea() {
    return this.$store.state.hierarchy.areaSelected;
  }

  set selectedArea(val: number) {
    const area = this.areas.find((a) => a.id === val);
    this.$store.commit('hierarchy/updateAreaSelected', area);
    this.$store.dispatch('hierarchy/getSubjects', area!.id);
  }

  get selectedSubject() {
    return this.$store.state.hierarchy.subjectSelected;
  }

  set selectedSubject(val: number) {
    const subject = this.subjects.find((s) => s.id === val);
    this.$store.commit('hierarchy/updateSubjectSelected', subject);
    this.$store.dispatch('hierarchy/getTopicSubtopics', subject!.id);
  }

  get selectedTopicSubtopic() {
    return store.state.hierarchy.topicSubtopicSelected;
  }

  set selectedTopicSubtopic(topicSubtopicId: any) {
    const val: TopicSubtopic = this.topicSubtopics.find(topicSubtopic => topicSubtopic.id === topicSubtopicId) ||
      this.topicSubtopics[0];
    if (val.questionCount < 20) {
      this.selectedSection = 0;
    } else {
      this.selectedSection = 1;
    }
    store.commit('hierarchy/updateTopicSubtopicSelected', val);
  }

  get selectedSection() {
    return store.state.hierarchy.sectionSelected;
  }

  set selectedSection(value) {
    store.commit('hierarchy/updateSectionSelected', value);
  }

  // Needed as data, so it can be used in the vue.
  data() {
    return {
      HierarchyLevel
    };
  }

}

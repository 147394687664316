import Vue from 'vue';
import store from '@/store';

interface IProps {
  action: string;
}

// No sub, no clicky.
function noSubClickHandler(evt: Event) {
  evt.preventDefault();
  store.commit('updateShowSubscriptionBottomSheet', true);
}

export default Vue.component<IProps>('SubCheckWrapper', {
  functional: true,
  render: (createElement, context) => {
    let hasAccess = false;
    let subLevel = store.state.user.subscriptionLevel;
    const action = context.props.action;
    if (store.state.permissions[action] !== undefined) {
      hasAccess = store.state.permissions[action].indexOf(subLevel) !== -1;
    }

    if (!hasAccess) {
      // Does not have the appropriate subscription level, wrap the item
      // with a div, capturing click events.  When a click event occurs, show
      // the subscription sheet.

      // Two divs are created, one to catch clicks in order to show the
      // subscription needed sheet.  Another to disable all mouse interaction.

      // First the no click div.
      const noClickOptions = {
        style: {
          pointerEvents: 'none'
        }
      };
      const noClickDiv = createElement('div', noClickOptions, context.children);

      // Now wrap up the no click div.
      const options = {
        on: {
          click: noSubClickHandler
        },
        style: {
          position: 'relative'
        }
      };
      return createElement('div', options, [noClickDiv]);
    }

    return createElement('span', context.data, context.children);
  }
});



















import { Component, Prop, Mixins } from 'vue-property-decorator';
import BaseCardMixin from '@/components/dashboard/BaseCardMixin';

@Component
export default class BaseCard extends Mixins(BaseCardMixin) {

  // To display a toolbar specify the toolbarColor.
  @Prop({default: () => null})
  toolbarColor: string | null;

  @Prop({default: () => false})
  toolbarDark: boolean;
}

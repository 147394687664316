



























import { IStatusDictionary } from '../../util/apiresponse';
import Component from 'vue-class-component';
import Vue from 'vue';

@Component
export default class RecoverPassword extends Vue {
  requestingPassword = false;
  requestSuccess = false;
  valid = false;
  email = '';
  errorMessage = '';

  emailRules = [
    (v: string) => !!v || 'E-mail is required.',
    (v: string) => {
      return (
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/.test(v.toUpperCase()) ||
        'E-mail must be valid.'
      );
    }
  ];

  async recoverPassword() {
    if (!this.valid) {
      this.errorMessage = 'Please enter your email.';
      return;
    }

    if (this.requestSuccess) {
      return;
    }

    this.errorMessage = '';
    this.requestingPassword = true;
    this.requestSuccess = false;

    try {
      let res = await this.$store.state.axios.post('api/user/password/recover',
        {
          email_address: this.email
        });

      res = res.data as IStatusDictionary;

      if (res.status) {
        this.requestSuccess = true;
      } else {
        this.errorMessage = res.error_msg || 'Unexpected error, sorry';
      }
    } catch (e) {
      console.log(e);
      this.errorMessage =
        'Error communicating with the server, try again later.';
    }
    this.requestingPassword = false;
  }

  cancel() {
    this.$router.push({ name: 'index'});
  }

}















































import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import {Mutation, State} from 'vuex-class';
import * as Logger from 'js-logger';
import {User} from '@/model/user';
import QuizHierarchy from '@/model/quizHierarchy';
import {HierarchyLevel} from '@/model/hierarchy';
import HierarchyConfigurator from '@/components/dashboard/study/test/hierarchy-configurator.vue';
import {IStatusDictionary} from '@/util/apiresponse';
import {IHierarchyState} from '@/store/modules/hierarchy';

const log = Logger.get('precfg-exam-select');

@Component({
  components: { HierarchyConfigurator }
})
export default class ExamCreateCustom extends Vue {
  hierarchyLevel = HierarchyLevel.Subject;
  anyLevel = HierarchyLevel.Area;

  @State('hierarchy')
  hierarchy: IHierarchyState;

  @State(state => state.serverConfig.exam_max_questions)
  maxNumberQuestions: number;

  @State('user')
  user: User;

  @Mutation('user/updateUserAttributes')
  updateUserAttributes: any;

  numberOfQuestions: number = 1;
  passingGrade: number = 1;

  errorMessage = '';
  amBusy = '';

  async create() {
    const validated = await this.$validator.validateAll();
    if (!validated) {
      return;
    }

    const setErrorMsg = (val: IStatusDictionary | null) => {
      const unexpected = 'Unexpected error occurred.';

      if (!val || val.unhandled_exception) {
        this.errorMessage = unexpected;
        return;
      }

      this.errorMessage = val.error_msg || unexpected;
    };

    try {
      if (!this.user.finalExamTokens) {
        this.$cookies.set('postPurchase', 'study-preconfigured-exam');
        this.$router.push({ name: 'store-final-exam-buy' });
      } else {
        this.amBusy = 'One second, creating your exam...';
        const areaId = this.hierarchy.areaSelected!.id === 'any' ? 'any' :
          Number(this.hierarchy.areaSelected!.id);
        const subjectId = this.hierarchy.subjectSelected!.id === 'any' ? 'any' :
          Number(this.hierarchy.subjectSelected!.id);
        const val = (await this.$store.state.axios.post(
          '/api/test/finalexam/take-custom',
          {
            area_category: Number(this.hierarchy.areaCategorySelected!.id),
            area: areaId,
            subject: subjectId,
            num_questions: Number(this.numberOfQuestions),
            passing_grade: Number(this.passingGrade)
          }
        )).data as IStatusDictionary;

        if (val.status) {
          // Final exam created successfully, update the user token count.
          this.updateUserAttributes({finalExamTokens: this.user.finalExamTokens - 1});

          this.$store.commit(
            'takeTest/updateQuizHierarchy',
            QuizHierarchy.fromSSQuizHierarchy(val.value.QuizHierarchy)
          );
          this.$router.push({ name: 'dashboard-study-take-test' });
        } else {
          if (val.tbinfo) {
            log.error('Unexpected request error', val);
          }
          setErrorMsg(val);
        }
      }
    } catch (e) {
      log.error('Error', e);
      setErrorMsg(null);
    } finally {
      this.amBusy = '';
    }
  }

  get questionNumValidator() {
    return `required|max_value:${this.maxNumberQuestions}|min_value:1`;
  }
}
















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import QuestionTracker from '@/model/quizHierarchy/question-tracker';
import QuestionTrackerView from '@/components/dashboard/study/test/question-tracker.vue';
import Question from '@/model/quizHierarchy/question';
import QuizHierarchy from '@/model/quizHierarchy';

@Component(
  {
    components: {
      QuestionTrackerView
    }
  }
)
export default class QuestionInfo extends Vue {
  @Prop(Question) question: Question;
  @Prop(QuizHierarchy) quizHierarchy: QuizHierarchy;
}

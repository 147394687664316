























import jsLogger from 'js-logger';
import Vue from 'vue';
import bus from '@/util/eventbus';
import {Component, Prop} from 'vue-property-decorator';
import IComment from '@/model/discuss/comment';
import Reply from './reply-or-edit.vue';
import {IStatusDictionary} from '@/util/apiresponse';
import {Mutation} from 'vuex-class';
import {IParentChildComment} from '@/store/modules/question-discussion';

const logger = jsLogger.get('cmp.discuss.comment');

if (process.env.NODE_ENV !== 'production') {
  logger.setLevel(jsLogger.DEBUG);
}

@Component(
  {
    components: {Reply},
    name: 'Comment'
  }
)
export default class Comment extends Vue {

  // How deeply nested the reply is.  A first level comment has a replyLevel of 0.
  // A reply to a first level comment has a replyLevel of 1.  A reply to this would
  // be a reply level of 2, and so on...
  @Prop({default: 0})
  replyLevel: number;

  @Prop({required: true})
  comment: IComment;

  @Mutation('questionDiscuss/commentDelete')
  commentDelete: (comment: IComment) => void;

  @Mutation('questionDiscuss/commentEdit')
  commentEdit: (updatedComment: IComment) => void;

  @Mutation('questionDiscuss/commentNew')
  commentNew: (commentPayload: IParentChildComment) => void;

  amReplying_ = false;

  amEditing_ = false;

  onCancelReply() {
    this.amReplying = false;
  }

  onCancelEdit() {
    this.amEditing = false;
  }

  onPostedEdit(comment: IComment) {
    this.commentEdit(comment);
    this.amEditing = false;
  }

  onPostedReply(comment: IComment, parent: IComment) {
    this.commentNew({comment, parent});
    this.amReplying = false;
  }

  async onDeleteBtn() {
      let res;
      res = await this.$store.state.axios.get(`/api/discuss/question/delete/${this.comment.id}`);
      res = res.data as IStatusDictionary;

      if (res.status) {
        this.commentDelete(this.comment);
      }
  }

  get cardStyle() {
    const leftMargin = this.replyLevel * 20;
    return {
      marginLeft: `${leftMargin}px`
    };
  }

  get canEdit() {
    return this.$store.state.user.id === this.comment.uid;
  }

  get canDelete() {
    return this.canEdit && this.comment.replies.length === 0;
  }

  get amReplying() {
    return this.amReplying_;
  }

  set amReplying(val: boolean) {
    this.amReplying_ = val;
    bus.$emit('amPostingOrEditingReply', val);
  }

  get amEditing() {
    return this.amEditing_;
  }

  set amEditing(val: boolean) {
    this.amEditing_ = val;
    bus.$emit('amPostingOrEditingReply', val);
  }
}



































































import jsLogger from 'js-logger';
import Vue from 'vue';
import { Mutation, State } from 'vuex-class';
import {Component, Prop} from 'vue-property-decorator';
import DashboardNotifications from '@/views/dashboard-notifications.vue';
import ChangePasswordDlg from '@/components/dashboard/appbar/change-password-dlg.vue';
import {User} from '@/model/user';
import store from '@/store';

const logger = jsLogger.get('cmp.AppBar');

if (process.env.NODE_ENV !== 'production') {
    logger.setLevel(jsLogger.DEBUG);  
}

@Component(
  {
    components: { DashboardNotifications, ChangePasswordDlg }
  }
)
export default
class AppBar extends Vue {

  @Mutation('updateAppDrawer')
  updateAppDrawer: (appDrawer: boolean) => void;

  @State('appDrawer')
  appDrawer: boolean;

  changePasswordDialog = false;

  get user() {
    return new User(store.state.user);
  }

  get subStatus() {
    const sub = this.user.subscriptionLevel;
    return sub === '' ? 'None' : sub;
  }

  onChangePassword() {
    this.changePasswordDialog = true;
  }

  updateAppBarNavIcon() {
    this.updateAppDrawer(!this.appDrawer);
  }

  setDarkMode(darkMode: boolean) {
    this.$vuetify.theme.dark = darkMode;
  }
}


























































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import StoreCard from './store-card.vue';
import {State} from 'vuex-class';
import {IServerConfig} from '@/model/serverConfig';
import {IStatusDictionary} from '@/util/apiresponse';
import * as Logger from 'js-logger';


const logger = Logger.get('cmp.views.store.index');

@Component(
  {
    components: {
      StoreCard
    }
  }
)
export default class DashboardStore extends Vue {

  @State('serverConfig')
  serverConfig: IServerConfig;

  errorMessage = '';

  showMustLoginDlg = false;

  async mounted() {
    if (!this.serverConfig) {
      logger.debug('Fetching server configuration');
      let res = await this.$store.state.axios.get('/api/store/serverconfig');
      res = res.data as IStatusDictionary;

      logger.debug('server configuration respone: ', res);
      if (!res.status) {
        this.errorMessage = res.error_msg;
      } else {
        this.$store.commit('updateServerConfig', res.value);
      }
    }
  }

  subscriptionLearnMore() {
    this.$router.push({name: 'store-sub-learn-more'});
  }

  subscriptionPurchase() {
    if (!this.$store.state.jwtToken) {
      this.showMustLoginDlg = true;
    } else {
        this.$router.push({name: 'store-sub-learn-more'});
    }
  }

  buyFinalExam() {
    if (!this.$store.state.jwtToken) {
      this.showMustLoginDlg = true;
    } else {
      this.$router.push({name: 'store-final-exam-buy'});
    }
  }

  studyguideLearnMore() {
    this.$router.push({name: 'studyguide-learn-more'});
  }

  studyguidePurchase() {
    if (!this.$store.state.jwtToken) {
      this.showMustLoginDlg = true;
    } else {
      this.$router.push({name: 'dashboard-study-studyguide-create'});
    }
  }

  get noSubroute() {
    return this.$route.path === '/store' || this.$route.path === '/store/';
  }
}


































import jsLogger from 'js-logger';
import * as Logger from 'js-logger';
import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import {IStatusDictionary} from '@/util/apiresponse';
import ScoreChart from '@/components/score/score-chart.vue';

const logger = Logger.get('cmp.score');

if (process.env.NODE_ENV !== 'production') {
  logger.setLevel(jsLogger.DEBUG);
}

interface IScore {
  Id: number;
  Date: string;
  Name: string;
  Score: number;
}


const GENERIC_ERR_MSG = 'Error fetching scores, please try again later.';
@Component({
  components: {ScoreChart}
})
export default
class Scores extends Vue {
  errorMessage = '';
  amBusy = true;
  numLastScores_ = 50;
  scores: IScore[] = [];
  showMenu = false;
  scoreChunks = [25, 50, 75, 100];
  // x y menu coordinates.
  x = 0;
  y = 0;

  async mounted() {
    await this.updateNumLastScores(this.numLastScores);
  }

  async fetchScores(): Promise<IScore[]> {
    this.amBusy = true;
    try {
      let res = await this.$store.state.axios.get(`/api/test/score/last/${this.numLastScores_}`);
      res = res.data as IStatusDictionary;
      logger.debug('fetched score res', res);

      if (!res.status) {
        throw new Error(GENERIC_ERR_MSG);
      } else {
        return res.value;
      }
    } finally {
      this.amBusy = false;
    }
  }

  showTheMenu(e) {
    this.x = e.clientX;
    this.y = e.clientY;
    this.showMenu = true;
  }

  async updateNumLastScores(scoreChunk) {
    this.numLastScores = scoreChunk;
    this.scores = await this.fetchScores();
    this.numLastScores_ = this.scores.length;
    this.showMenu = false;
  }

  get numLastScores() {
    return this.numLastScores_;
  }

  set numLastScores(numScores: number) {
    this.numLastScores_ = numScores;
  }
}

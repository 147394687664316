import Answer from './answer';
import QuestionTracker from './question-tracker';
import IQuestion from '@/model/discuss/question';
import IComment from '@/model/discuss/comment';

export default class Question {
  /**
   * Convert the question object from seasources into a typescript Question.
   *
   * @param object Question object returned from seasources.
   */
  public static fromSSQuestion(object: any): Question {
    const question = new Question();
    Object.assign(question, object);

    // Convert answers.
    question.Answers = question.Answers.map((answer: Answer) => {
      const newAnswer = new Answer();
      newAnswer.AnswerText = answer.AnswerText;
      newAnswer.IsCorrect = answer.IsCorrect;
      return newAnswer;
    });

    // Convert question trackers.
    const sysQuestionTracker = new QuestionTracker();
    sysQuestionTracker.CorrectCount = object.SysQuestionTracker.CorrectCount;
    sysQuestionTracker.MissCount = object.SysQuestionTracker.MissCount;
    question.SysQuestionTracker = sysQuestionTracker;

    const userQuestionTracker = new QuestionTracker();
    userQuestionTracker.CorrectCount = object.UserQuestionTracker.CorrectCount;
    userQuestionTracker.MissCount = object.UserQuestionTracker.MissCount;
    question.UserQuestionTracker = userQuestionTracker;
    return question;
  }

  public Id: number;
  public QuestionText: string;
  public Answers: Answer[];
  public Graphics: string[];
  public Assets: string[];
  public Comments: IComment[];
  public Reference: string;
  public SysQuestionTracker: QuestionTracker;
  public UserQuestionTracker: QuestionTracker;

  public asDiscussQuestion(): IQuestion {
    let correctAnswer = this.Answers.filter((a: Answer) => a.IsCorrect);
    let answer;

    if (correctAnswer.length === 0) {
      answer = 'No correct answer specified for this question.  This is a bug.';
    } else {
      answer = correctAnswer[0];
    }

    return {
      qid: this.Id,
      question: this.QuestionText,
      answer,
      comments: this.Comments,
      graphics: this.Graphics,
      assets: this.Assets,
      reference: this.Reference
    };
  }

}

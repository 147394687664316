















  import {Component, Prop} from 'vue-property-decorator';
  import Vue from 'vue';
  import {State} from 'vuex-class';
  import {INotificationState} from '@/store/modules/notification';

  @Component
export default class DownloadStudyguideDialog extends Vue {
  @State('notification')
  notification: INotificationState;

  // value ius the default prop used for v-model.
  @Prop({default: () => false})
  value: boolean;

  get showDialog() {
    return this.value;
  }

  // When the dialog updates the showDialog switch, fire off the input event.
  // the input event is the default event used by v-model.
  set showDialog(value: boolean) {
    this.$emit('input', value);
  }
}

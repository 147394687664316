

















import BaseCard from '@/components/dashboard/BaseCard.vue';
import BaseCardMixin from '@/components/dashboard/BaseCardMixin';
import { Component, Mixins } from 'vue-property-decorator';
import Vue from 'vue';

@Component(
  {
    components: {BaseCard}
  }
)
export default class MakeFinalExam extends Mixins(BaseCardMixin)  {

}

import Vue from 'vue';
import Vuex, { Store, ModuleTree } from 'vuex';
import { IState, createState } from './state';
import mutations from './mutations';
import { createModule as createModuleRegistration } from './modules/registration';
import { createModule as createModuleUserForm } from './modules/userform';
import { createModule as createModuleUser } from './modules/user';
import { createModule as createModuleHierarchy } from './modules/hierarchy';
import { createModule as createModuleTakeTest } from './modules/taketest';
import { createModule as createModuleNotification } from './modules/notification';
import { createModule as createModuleQuestionDiscuss } from './modules/question-discussion';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

Vue.config.devtools = debug;

export function createStore(modules: ModuleTree<IState> | null = null): Store<IState> {
    const registration = createModuleRegistration();
    const userform = createModuleUserForm();
    const user = createModuleUser();
    const hierarchy = createModuleHierarchy();
    const takeTest = createModuleTakeTest();
    const notification = createModuleNotification();
    const questionDiscuss = createModuleQuestionDiscuss();

    if (modules == null) {
        modules = { registration, userform };
    }

    if (!modules.registration) {
        modules.registration = registration;
    }

    if (!modules.userform) {
        modules.userform = userform;
    }

    if (!modules.user) {
        modules.user = user;
    }

    if (!modules.hierarchy) {
        modules.hierarchy = hierarchy;
    }

    if (!modules.takeTest) {
        modules.takeTest = takeTest;
    }

    if (!modules.notification) {
        modules.notification = notification;
    }

    if (!modules.questionDiscuss) {
      modules.questionDiscuss = questionDiscuss;
    }

    return new Store<IState>({
        state: createState(),
        mutations,
        modules,
        strict: debug
    });
}

export default createStore();












































import { IStatusDictionary } from '../../util/apiresponse';
import Component from 'vue-class-component';
import Vue from 'vue';

@Component
export default class PasswordReset extends Vue {
  requestingPassword = false;
  requestSuccess = false;
  password = '';
  passwordValidate = '';
  errorMessage = '';

  async resetPassword() {
    if (this.$validator.errors.any()) {
      return;
    }
    this.errorMessage = '';
    this.requestingPassword = true;
    this.requestSuccess = false;

    try {
      let res = await this.$store.state.axios.get(
        `api/user/password/reset/${this.$route.params.uid}/${this.$route.params.hash}/${this.password}`);
      res = res.data as IStatusDictionary;

      if (res.status) {
        this.requestSuccess = true;
      } else {
        this.errorMessage = res.error_msg || 'Unexpected error, sorry.';
      }
    } catch (e) {
      console.log(e);
      this.errorMessage =
        'Error communicating with the server, try again later.';
    }
    this.requestingPassword = false;
  }

  cancel() {
    this.$router.push({ name: 'index'});
  }

}


























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IStatusDictionary } from '@/util/apiresponse';
import * as Logger from 'js-logger';
import { ssDictToUserDict } from '@/model/user';
const log = Logger.get('confirm-purchase');

interface IItem {
  name: string;
  sku: string;
  description: string;
  price: string;
  currency: string;
  quantity: number;
}

interface IVerifyPurchaseDict {
  total: string;
  invoiceNumber: string;
  description: string;
  items: IItem[];
}

@Component
export default class ConfirmPurchase extends Vue {

  amExecutingPayment = false;
  busyMessage = '';
  errorMessage = '';
  successMessage = '';

  verifyPurchaseDict = {} as IVerifyPurchaseDict;

  // Override this if subclassing and you need to do something after a successful purchase.
  onSuccessfulPurchase() {
    this.successMessage = 'Purchase complete, thanks for supporting Seasources!  Your purchase' +
      ' is active and ready to be used.';
    log.info('onSuccessfulPurchase not overridden');
  }

  async mounted() {
    // Fetch payment verification info.
    this.busyMessage = 'Hang on, fetching purchase details...';
    try {
      const query = this.$route.query;
      let res = await this.$store.state.axios.get(`/api/store/verify-purchase/${query.paymentId}`);
      res = res.data as IStatusDictionary;
      log.info('verify-purchase respones', res);
      if (res.status === 0) {
        this.errorMessage = res.error_msg ||
          'Unexpected error, please contact us.  (You have not been charged anything).';
      } else {
        this.verifyPurchaseDict = res.value;
      }
    } catch (e) {
      this.errorMessage = 'There was an issue fetching the details, you have not been charged.';
    } finally {
      this.busyMessage = '';
    }
  }

  async pay() {
    try {
      this.amExecutingPayment = true;
      const query = this.$route.query;

      let res = await this.$store.state.axios.get('/api/store/execute-payment',
        {
          params: {
            payment_id: query.paymentId,
            payer_id: query.PayerID,
            token: query.token
          }
        }
      );
      res = res.data as IStatusDictionary;

      log.info('execute payment response => ', res);
      if (res.status === 0) {
        this.errorMessage = res.error_msg || 'Unexpected error occurred.';
      } else {
        this.$store.commit('user/updateUser', ssDictToUserDict(res.value));
        this.successMessage = 'Purchase success.';
        this.onSuccessfulPurchase();
      }
    } catch (e) {
      this.errorMessage = 'Error processing your payment.  Please contact us ' +
        'if this issue persists.';
      log.error('Error fetching final exam', e);
    } finally {
      this.amExecutingPayment = false;
    }
  }

  cancel() {
    this.$router.push({name: 'dashboard-home'});
  }
}

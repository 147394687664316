


























import Component from 'vue-class-component';
import BaseCard from '../BaseCard.vue';
import { IsAreaOfStudyActive } from '@/util';

@Component(
  {
    components: {
      BaseCard
    }
  }
)
export default class TodaysTest extends BaseCard {
  takeTest() {
    this.$router.push({name: 'dashboard-area-of-study'});
  }

  get configureTestRoute() {
    return IsAreaOfStudyActive ? 'dashboard-area-of-study' : 'dashboard-study-configure';
  }
}



































import Vue from 'vue';
import Component from 'vue-class-component';
import * as Logger from 'js-logger';
import {PropSync, Watch} from 'vue-property-decorator';
import {ApiCallFailed} from '@/util/apicallfailed';

const log = Logger.get('ChangePasswordDlg');

interface IMessageType {
  type: string;
  message: string;
}

@Component
export default class ChangePasswordDlg extends Vue {
  busy = false;
  valid = false;
  currentPassword = '';
  newPassword = '';
  newPasswordReenter = '';

  passwordRules = [
      v => !!v || 'Password is required',
      v => (v && v.length >= 5) || 'Password must be more than 5 characters'
  ];

  message = {
    type: '',
    message: ''
  } as IMessageType;

  // Called when the dialog is closed.
  @PropSync('showDialog', {type: Boolean})
  showDialogSync?: boolean;


  @Watch('newPassword')
  onnewPasswordChanged() {
    this.validateField();
  }

  @Watch('newPasswordReenter')
  onnewPasswordReenterChanged() {
    this.validateField();
  }

  get passwordMismatch() {
    return [
      v => (v.length && v === this.newPasswordReenter) || 'Passwords do not match'
    ];
  }

  async changePassword() {
    console.log(this.currentPassword, this.newPassword);
    try {
      this.busy = true;
      log.info('Resetting password.');

      await this.$api.get(
        `api/user/password/reset/ct/${this.currentPassword}/${this.newPassword}`);

      this.message.type = 'success';
      this.message.message = 'Password updated successfully.';
    } catch (e) {
      this.message.type = 'error';
      if (e instanceof ApiCallFailed) {
        this.message.message = e.message;
        log.debug('API Call Failed', e.message);
        log.debug('API Call failed status dict:', e.statusDict);
      } else {
        this.message.message = 'Unexpected error occurred';
        log.error('Error updating password: ', e);
      }
    } finally {
      this.busy = false;
    }
  }

  validateField() {
    (this.$refs.form as any).validate();
  }
}

import {Mutation, MutationTree} from 'vuex';
import {IState} from './state';
import {IServerConfig} from '@/model/serverConfig';

export interface IMutations<S> extends MutationTree<S> {
  updateAppDrawer: Mutation<S>;

  updateServerConfig: Mutation<S>;

  updatePermissions: Mutation<S>;

  updateShowReportBugDlg: Mutation<S>;

  updateJwtToken: Mutation<S>;

  SOCKET_CONNECT: Mutation<S>;

  updateChatEnabled: Mutation<S>;

  updateChatOpen: Mutation<S>;

  updateShowSubscriptionBottomSheet: Mutation<S>;
}

export default {
  updateAppDrawer: (state: IState, appDrawer: boolean) => {
    state.appDrawer = appDrawer;
  },

  updateServerConfig: (state: IState, serverConfig: IServerConfig) => {
    state.serverConfig = serverConfig;
  },
  updatePermissions: (state: IState, permissions: any) => {
      state.permissions = permissions;
  },
  updateShowReportBugDlg: (state: IState, show: boolean) => {
      state.showReportBugDlg = show;
  },
  updateJwtToken: (state: IState, jwtToken: string) => {
      state.jwtToken = jwtToken;
  },
  SOCKET_CONNECT(state: IState) {
      state.socketConnected = true;
  },
  updateChatEnabled(state: IState, chatEnabled: boolean) {
    state.chatEnabled = chatEnabled;
  },
  updateChatOpen(state: IState, chatOpen: boolean) {
    state.chatOpen = chatOpen;
  },
  updateShowSubscriptionBottomSheet(state: IState, showBottomSheet: boolean) {
    state.showSubscriptionBottomSheet = showBottomSheet;
  }
} as IMutations<IState>;























import Component, {mixins} from 'vue-class-component';
import * as Logger from 'js-logger';
import {IHierarchyNode} from '@/model/hierarchy';
import Message from '@/components/message.vue';
import MessageMixin from '@/components/message-mixin';
import {ApiCallFailed} from '@/util/apicallfailed';

const log = Logger.get('TakeTestPickAreaOfStudy');

@Component(
  {components: {Message}}
)
export default class TakeTestPickAreaOfStudy extends mixins(MessageMixin) {
  areaOfStudy: number = 0;

  areasOfStudy: IHierarchyNode[] = [];

  async mounted() {
    try {
      this.setPendingMessage('Hang on, fetching areas of study...');
      this.areasOfStudy = await this.$api.get('/api/test/hierarchy/area-of-study');
      this.setNoMessage();
    } catch (e) {
      if (e instanceof ApiCallFailed) {
        this.setErrorMessage(e.message);
        log.error('API Call Failed', e.message);
        log.error('API Call failed status dict:', e.statusDict);
      } else {
        this.setErrorMessage('Unexpected error occurred, try again later.');
        log.error('Error updating password: ', e);
      }
    }
  }

  onAreaOfStudySelected() {
    this.$router.push({
      name: 'dashboard-study-configure-with-aos',
      params: {
        areaOfStudyId: String(this.areasOfStudy[this.areaOfStudy].id)
      }
    });
    if (this.areasOfStudy) {
      console.log(this.areasOfStudy[this.areaOfStudy]);
    }
  }
}

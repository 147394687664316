/**
 * The namespaced module to be used by the store.
 */
import { Module, ActionTree, ActionContext } from 'vuex';
import { IState } from '../state';
import IUserModel from '../../model/user';
const debug = process.env.NODE_ENV !== 'production';

import { Mutation, MutationTree } from 'vuex';
import jsLogger from 'js-logger';

const Logger = jsLogger.get('store.registration');

export interface IRegistrationErrors extends IUserModel {
    // Unexpected server side error (like database error).
    unexpectedError: string;
}

export interface IRegistrationState extends IUserModel {
    validForm: boolean;
    amProcessingRegistration: boolean;
    userRegistered: boolean;

    // If errors are an IUserModel instance then these are validation errors
    // returned from the server side.
    errors?: IRegistrationErrors;
}

interface ISeasourcesUserDict {
    first_name: string;
    middle_name: string;
    last_name: string;
    email_address: string;
    password: string;
    password_validation: string;
    current_license: string;
    desired_license: string;
    comments: string;
    [key: string]: any;
}

export const RegistrationState: IRegistrationState = {
    firstName: '',
    middleName: '',
    lastName: '',
    emailAddress: '',
    password: undefined,
    passwordValidate: undefined,
    currentLicense: '',
    desiredLicense: '',
    comments: '',
    validForm: false,
    amProcessingRegistration: false,
    userRegistered: false,
    errors: undefined
};

function userModelToSeasourcesUserDict(userModel: IUserModel): ISeasourcesUserDict {
    return {
        first_name: userModel.firstName,
        middle_name: userModel.middleName,
        last_name: userModel.lastName,
        email_address: userModel.emailAddress,
        password: userModel.password || '',
        password_validation: userModel.password || '',
        current_license: userModel.currentLicense,
        desired_license: userModel.desiredLicense,
        comments: userModel.comments
    };
}

// Mutations
interface IRegistrationMutation<S> extends MutationTree<S> {
    updateUser: Mutation<S>;
}

const mutations: IRegistrationMutation<IRegistrationState> = {
    // commit('registration/updateUser')
    updateUser(state: IRegistrationState, user: IUserModel) {
        Object.assign(state, user);
    },
    updateAmProcessingRegistration(state: IRegistrationState, amProcessingRegistration: boolean) {
        state.amProcessingRegistration = amProcessingRegistration;
    },
    updateUserRegistered(state: IRegistrationState, userRegistered: boolean) {
        state.userRegistered = userRegistered;
    },
    updateUserRegistrationErrorsFromForm(state: IRegistrationState, errorFields: any[]) {
        // Called when errors occur on UserForm.  Propagate the errors up from
        // userform to the registration form.
        if (!state.errors) {
            state.errors = {} as IRegistrationErrors;
        }
        for (const errorField of errorFields) {
            // The UserForm field names start with model. so remove it.
            state.errors[errorField.field.replace('model.', '')] = errorField.msg;
        }
    },
    updateUserRegistrationErrors(state: IRegistrationState, errors: ISeasourcesUserDict | string) {
        interface IMap<T> {
            [key: string]: T;
        }

        const fieldNameToStoreName = {
            first_name: 'firstName',
            last_name: 'lastName',
            email_address: 'emailAddress',
            password: 'password',
            password_validate: 'password.validate'
        } as IMap<string>;

        // Remap the seasources server side errors onto the errors object.
        if (isErrorDict(errors)) {
            if (!state.errors) {
                state.errors = {} as IRegistrationErrors;
            }
            for (const fieldName of Object.keys(errors)) {
                const storeName = fieldNameToStoreName[fieldName];
                // Errors are returned as an array of strings, take the first one.
                state.errors[storeName] = errors[fieldName][0] as string;
            }
        } else {
            // Error message is a raw string.
            state.unexpectedError = errors;
        }
    },
    updateValidForm(state: IRegistrationState, validForm: boolean) {
        state.validForm = validForm;
    }
};

function isErrorDict(errors: ISeasourcesUserDict | string): errors is ISeasourcesUserDict {
    // Check for one of the attributes.
    const maybeDict = errors as ISeasourcesUserDict;
    return maybeDict.first_name !== undefined ||
        maybeDict.last_name !== undefined ||
        maybeDict.email_address !== undefined ||
        maybeDict.password !== undefined ||
        maybeDict.password_validation !== undefined;
}

// Actions are similar to mutations, the differences being that:
//   - Instead of mutating the state, actions commit mutations.
//   - Actions can contain arbitrary asynchronous operations.
//
interface IRegistrationActionTree<S, R> extends ActionTree<S, R> {
    doRegistration(injectee: ActionContext<S, R>, payload: any): any;
}

const actions: IRegistrationActionTree<IRegistrationState, IState> = {
    async doRegistration(context: ActionContext<IRegistrationState, IState>, user: IUserModel) {
        // Send off the registration.
        context.commit('updateAmProcessingRegistration', true);

        try {
            const axios = context.rootState.axios;
            const ssUserDict = userModelToSeasourcesUserDict(user);
            Logger.debug('About to call user create with', ssUserDict);
            let res = await axios.post('api/user/create', ssUserDict);
            res = res.data;
            Logger.debug('Registration request returned ', res);
            if (res.status === 1) {
                context.commit('updateValidForm', true);
                context.commit('updateUserRegistered', true);
                context.commit('updateUserRegistrationErrors', null);
            } else {
                context.commit('updateValidForm', false);
                context.commit('updateUserRegistered', false);
                // Set error message per the user dictionary.
                context.commit('updateUserRegistrationErrors', res.data.errors);
            }
        } catch (err) {
            context.commit('updateUserRegistrationErrors', 'Unexpected registration error: ' + err);
        }
        context.commit('updateAmProcessingRegistration', false);
    },
};

/**
 * Create a registration module.
 *
 * @param state initial state for the registration module.
 */
export function createModule(state: IRegistrationState | null = null): Module<IRegistrationState, IState> {
    if (state === null) {
        state = Object.assign({}, RegistrationState);
    }

    return {
        namespaced: true,
        state,
        mutations,
        actions,
        strict: debug
    } as Module<IRegistrationState, IState>;
}

// Module
export default createModule();






























































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import { Validator, ErrorBag } from 'vee-validate';
import store from '../../store';
import IUserModel from '../../model/user';
import { IStatusDictionary } from '@/util/apiresponse';
import {IRegistrationErrors} from '@/store/modules/registration';

// Create a unique email validator.
function isUniqueEmail(email: string) {
  return store.state.axios.post<IStatusDictionary>('api/user/email/exists', {
    email_address: email
  }).then((res) => {
    return {
      valid: !res.data.exists,
      data: {
        isUniqueEmail: !res.data.exists
      }
    };
  }).catch(reason => {
    console.log(`isUniqueEmail validator failure ${reason}`, reason);
    return {
      valid: true,
      data: {
        isUniqueEmail: true
      }
    };
  });
}

Validator.extend('uniqueEmail', {
  validate: isUniqueEmail,
  getMessage(field: string, params: any[], data: any) {
    return data.isUniqueEmail ? '' : 'Email already in use.';
  }
});

const emptyModel: IUserModel = {
    firstName: '',
    middleName: '',
    lastName: '',
    emailAddress: '',
    password: '',
    passwordValidate: '',
    currentLicense: '',
    desiredLicense: '',
    comments: ''
  };

@Component
export default class UserForm extends Vue {

  // IUserModel instance.  Where each associated value is an error for the
  // form's UserModel.
  @Prop({default: (): IRegistrationErrors => emptyModel as IRegistrationErrors })
  verrors: IRegistrationErrors;

  @Prop({default: false})
  hideLicenseComment: false;

  @Prop({default: (): IUserModel => emptyModel})
  initModel: IUserModel;

  // Initialized when mounted.
  model: IUserModel = { ...emptyModel };

  beforeMount() {
    this.model = { ... this.initModel };
    this.onErrorsChanged(this.verrors, null);
  }

  addErrors(errors: any[]) {
    for (const error of errors) {
      this.$validator.errors.add(error);
    }
  }

  validate(): Promise<boolean> {
    console.log('vall');
    this.$validator.errors.clear();
    const promise = this.$validator.validateAll();
    promise.then((result) => {
      this.$store.commit('userform/updateAll', {errors: this.$validator.errors});
    });
    return promise;
  }

  @Watch('verrors')
  onErrorsChanged(newVal: IUserModel, oldVal: IUserModel | null) {
    this.$store.commit('userform/updateAll', { errors: newVal });
  }
}

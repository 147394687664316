import Vue from 'vue';
import Component from 'vue-class-component';


// Useful interface for classes using Message.
export interface IMessage {
  messageType: string;
  message: string;
  fade?: number;
}

@Component
export default class MessageMixin extends Vue {
  message: IMessage = {
    messageType: '',
    message: '',
    fade: undefined
  };

  public setNoMessage() {
    this.message.messageType = '';
    this.message.message = '';
  }

  public setMessage(messageType: string, message: string, fade?: number): void {
    const m = this.message;
    m.message = message;
    m.messageType = messageType;
    m.fade = fade;
  }

  public setPendingMessage(message: string, fade?: number): void {
    this.setMessage('pending', message, fade);
  }

  public setErrorMessage(message: string, fade?: number): void {
    this.setMessage('error', message, fade);
  }
}






































import Vue from 'vue';
import Component from 'vue-class-component';
import IUserModel from '../../model/user';
import UserForm from '@/components/user/Form.vue';
import getLogger from '../../logger';

let logger = getLogger();

@Component({
  components: { UserForm }
})
export default class MyComponent extends Vue {

  unexpectedServerError: boolean = false;

  // Undefined values are *not* reactive.
  userForm: UserForm;

  // initModel: IUserModel = {} as IUserModel;

  initModel: IUserModel =
  {
    firstName: '',
    middleName: '',
    lastName: '',
    emailAddress: '',
    password: '',
    passwordValidate: ''
  } as IUserModel;

  constructor() {
    super();
    this.userForm = (this.$refs.userForm as UserForm);
  }

  get userRegistered(): boolean {
    return this.$store.state.registration.userRegistered;
  }

  mounted() {
    this.userForm = (this.$refs.userForm as UserForm);
  }

  /*
   * Send the registration request off to the server.
   *
   * The request will eventually update the this.$store.state.registration.errors
   * or the this.$store.state.registration.userRegistered property.
   *
   */
  register() {
    this.userForm.validate().then(async (result) => {
      if (result) {
        // This dispatch sets a handful of variables like userRegistered.
        logger.debug('Valid user form, performing registration with ', this.userForm.model);
        await this.$store.dispatch('registration/doRegistration', this.userForm.model);
      }
    });
  }

  // Set when server side registration errors occur.
  get verrors(): IUserModel {
    return this.$store.state.registration.errors;
  }

  /*
   * When the store changes, update serverSideErrors
   */
  get serverSideErrors(): boolean {
    return this.verrors !== undefined;
  }

  get serverSideRegistrationErrors(): boolean {
    // Store registration errors is set when server side validation fails.
    // But the field names have to be mapped back to store names.
    return this.$store.state.registration.errors !== null;
  }

  cancel(): void {
    this.$router.push({ name: 'index' });
  }
}

import Vue from 'vue';
import Router, {Location, Route} from 'vue-router';
import Login from '@/views/Login.vue';
import Register from '@/views/user/Register.vue';
import RecoverPassword from '@/views/user/RecoverPassword.vue';
import ValidateEmail from '@/views/user/ValidateEmail.vue';
import PasswordReset from '@/views/user/PasswordReset.vue';
import Dashboard from '@/views/Dashboard.vue';
import DashboardHome from '@/components/dashboard/Home.vue';
import DashboardStudy from '@/components/dashboard/study/DashboardStudy.vue';
import Configure from '@/components/dashboard/study/test/configure.vue';
import TakeTest from '@/views/TakeTest.vue';
import TestResults from '@/views/test/Results.vue';
import SelectPreconfiguredExam from '@/views/test/select-preconfigured.vue';
import ExamCreateCustom from '@/views/test/exam-create-custom.vue';
import FinalExamBuy from '@/views/store/finalexam/buy.vue';
import ConfirmPurchase from '@/views/store/confirmpurchase.vue';
import ConfirmPurchaseStudyguide from '@/views/store/confirm-purchase-studyguide.vue';
import StudyguideCreate from '@/views/studyguide/sg-create.vue';
import SubscriptionLearnMore from '@/views/store/subscription/learnmore.vue';
import StudyguideLearnMore from '@/views/store/studyguide/learnmore.vue';
import Search from '@/views/Search.vue';
import Scores from '@/views/scores.vue';
import Chat from '@/views/chat.vue';
import StoreIndex from '@/views/store/index.vue';
import TakeTestPickAreaOfStudy from '@/views/test/take-test-pick-area-of-study.vue';

import store from '@/store';
import DiscussQuestion from '@/views/DiscussQuestion.vue';
import DiscussIndex from '@/views/discuss/discuss-index.vue';


Vue.use(Router);

const router = new Router({
  scrollBehavior(to, from, savedPosition) {
    let scrollTo: number | string = 0;

    if (to.hash) {
      return {
        selector: to.hash
      };
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return {x: 0, y: scrollTo};
  },

  routes: [
    {
      path: '/',
      name: 'index',
      component: Login,
    },
    {
      path: '/next/:path',
      name: 'index-next',
      component: Login
    },
    {
      path: '/register',
      name: 'register',
      component: Register,
    },
    {
      path: '/password/recover',
      name: 'recoverPassword',
      component: RecoverPassword
    },
    {
      path: '/user/validate/:uid/:hash',
      name: 'user.validateEmail',
      component: ValidateEmail
    },
    {
      path: '/password/reset/:uid/:hash',
      name: 'passwordReset',
      component: PasswordReset
    },
    {
      path: '/store',
      name: 'store',
      component: StoreIndex,
      children: [
        {
          path: 'final-exam/buy',
          name: 'store-final-exam-buy',
          component: FinalExamBuy
        },
        {
          path: 'subscription',
          name: 'store-sub-learn-more',
          component: SubscriptionLearnMore
        },
        {
          path: 'studyguide',
          name: 'studyguide-learn-more',
          component: StudyguideLearnMore
        },
        {
          path: 'confirm-purchase',
          name: 'store-confirm-purchase',
          component: ConfirmPurchase
        },
        {
          path: 'confirm-purchase-studyguide',
          name: 'store-confirm-purchase-studyguide',
          component: ConfirmPurchaseStudyguide
        }
      ]
    },
    {
      path: '/dashboard/',
      name: 'dashboard',
      component: Dashboard,
      children: [
        // home
        {
          path: 'home',
          name: 'dashboard-home',
          component: DashboardHome
        },
        // study
        {
          path: 'study',
          name: 'dashboard-study',
          component: DashboardStudy,
          children: [
            {
              path: 'configure',
              name: 'dashboard-study-configure',
              component: Configure
            },
            {
              path: 'configure/:areaOfStudyId',
              name: 'dashboard-study-configure-with-aos',
              component: Configure
            },
            {
              path: 'area-of-study',
              name: 'dashboard-area-of-study',
              component: TakeTestPickAreaOfStudy
            },
            {
              path: 'take',
              name: 'dashboard-study-take-test',
              component: TakeTest
            },
            {
              path: 'results',
              name: 'dashboard-study-test-results',
              component: TestResults
            },
            {
              path: 'preconfigured-exam',
              name: 'dashboard-study-preconfigured-exam',
              component: SelectPreconfiguredExam
            },
            {
              path: 'exam-create-custom',
              name: 'dashboard-study-exam-create-custom',
              component: ExamCreateCustom
            },
            {
              path: 'studyguide-create',
              name: 'dashboard-study-studyguide-create',
              component: StudyguideCreate
            }
          ]
        },
        // discuss
        {
          path: 'discuss',
          name: 'dashboard-discuss-index',
          component: DiscussIndex
        },
        {
          path: 'discuss/p/:page',
          name: 'dashboard-discuss-index-page',
          component: DiscussIndex
        },
        {
          path: 'discuss/:qid',
          name: 'dashboard-discuss',
          component: DiscussQuestion
        },
        // search
        {
          path: 'search',
          name: 'dashboard-search',
          component: Search
        },
        // search with query
        {
          path: 'search/q/:query',
          name: 'dashboard-search-query',
          component: Search
        },
        // search with query and page
        {
          path: 'search/q/:query/p/:page',
          name: 'dashboard-search-query-page',
          component: Search
        },
        // scores
        {
          path: 'scores',
          name: 'dashboard-scores',
          component: Scores
        },
        // chat
        {
          path: 'chat',
          name: 'chat',
          component: Chat
        }
      ]
    }
  ],
});

// Guard against accessing routes without a login.
router.beforeEach((to: Route, from: Route, next) => {
  const NO_LOGIN_REQUIRED_ROUTES = ['index', 'index-next', 'register', 'recoverPassword',
    'user.validateEmail', 'passwordReset'];
  if (NO_LOGIN_REQUIRED_ROUTES.indexOf(to.name || '') > -1 ||
      to.path === '/store' || to.path === '/store/') {
    next();
  } else if (store.state.user.id === 0) {
    next({
      name: 'index-next',
      params: {
        path: to.name,
        params: JSON.stringify(to.params)
      },
      query: to.query
    } as Location);
  } else {
    next();
  }
});

export default router;






























// https://vegibit.com/vuejs-parent-child-communication/
import Component from 'vue-class-component';
import Vue from 'vue';
import {IUserDictionaryModel, User} from '@/model/user';
import {IStatusDictionary} from '@/util/apiresponse';
import store from '@/store';
import {Prop} from 'vue-property-decorator';
import {ApiCallFailed} from '@/util/apicallfailed';

enum ResendValidationEmailStatus {
  NotSending,
  Sending,
  SendError,
  SendSuccess
}

@Component
export default class TodaysTest extends Vue {
  @Prop({default: () => new User(store.state.user)})
  user: User;
  validationDialogShown: boolean = false;
  resendValidationEmailStatus: ResendValidationEmailStatus = ResendValidationEmailStatus.NotSending;

  // This allows the enum to be used in v-if.
  ResendValidationEmailStatus = ResendValidationEmailStatus;

  get showValidationDialog(): boolean {
    return !(this.user.verified || this.validationDialogShown);
  }

  async resendValidationEmail() {
    // Don't resend email.
    if (this.resendValidationEmailStatus === ResendValidationEmailStatus.SendSuccess) {
      return;
    }

    try {
      this.resendValidationEmailStatus = ResendValidationEmailStatus.Sending;
      await this.$api.get('/api/user/resend-validation');
      this.resendValidationEmailStatus = ResendValidationEmailStatus.SendSuccess;
    } catch (e) {
      this.resendValidationEmailStatus = ResendValidationEmailStatus.SendError;
    }
  }

}



































































import jsLogger from 'js-logger';
import * as Logger from 'js-logger';
import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import {IServerConfig, ISubscriptionDescription} from '@/model/serverConfig';
import {State} from 'vuex-class';
import BaseCard from '@/components/dashboard/BaseCard.vue';
import {IStatusDictionary} from '@/util/apiresponse';

const log = Logger.get('cmp.store.learnmore');


if (process.env.NODE_ENV !== 'production') {
    log.setLevel(jsLogger.DEBUG);
}

@Component(
  {
    components: { BaseCard }
  }
)
export default class LearnMore extends Vue {

  preparingPayment = false;
  errorMessage = '';

  @State('serverConfig')
  serverConfig: IServerConfig;

  get freeTests(): number {
    return this.serverConfig.tests_per_day;
  }

  get normalSubs(): ISubscriptionDescription[] {

    if (this.serverConfig.plus_subs_enabled) {
      return [
        this.serverConfig.sub_silver,
        this.serverConfig.sub_silver_plus,
        this.serverConfig.sub_gold,
        this.serverConfig.sub_gold_plus,
        this.serverConfig.sub_platinum,
        this.serverConfig.sub_platinum_plus
      ];
    }

    return [
      this.serverConfig.sub_silver,
      this.serverConfig.sub_gold,
      this.serverConfig.sub_platinum,
    ];
  }

  numTokens(subDesc: ISubscriptionDescription) {
    return subDesc.examTokens < 100 ? subDesc.examTokens : 'Unlimited';
  }

  duration(subDesc: ISubscriptionDescription) {
    return subDesc.months === 1 ? '1 month' : `${subDesc.months} months`;
  }

  async purchase(subDesc: ISubscriptionDescription) {
    this.preparingPayment = true;
    log.info('Prepping purchase for subDesc', subDesc);

    const baseUrl = location.protocol + '//' + location.host + '/';
    const cancelUrl = baseUrl + '#' + this.$route.path;
    const returnUrl = baseUrl + this.$router.resolve({
      name: 'store-confirm-purchase'
    }).href;

    try {
      let res = await this.$store.state.axios.post('/api/store/subscription/create-payment',
        {
          subscription_id: subDesc.id,
          return_url: returnUrl,
          cancel_url: cancelUrl
        });

      res = res.data as IStatusDictionary;

      log.debug('Create payment response: ', res);

      if (!res.status) {
        this.errorMessage = res.error_msg;
        return;
      }

      window.location.href = res.value.approval_url;
    } catch (e) {
      log.error('Error creating subscription payment ', e);
      this.errorMessage = 'Sorry an unexpected error occurred.';
      this.preparingPayment = false;
    }
  }
}

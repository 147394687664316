import { render, staticRenderFns } from "./Results.vue?vue&type=template&id=39041c54&scoped=true&lang=pug&"
import script from "./Results.vue?vue&type=script&lang=ts&"
export * from "./Results.vue?vue&type=script&lang=ts&"
import style0 from "./Results.vue?vue&type=style&index=0&id=39041c54&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39041c54",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCard,VCardText,VDivider,VFlex,VLayout,VProgressCircular,VTab,VTabItem,VTabs})
















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component
export default class Message extends Vue {
  show = true;

  @Prop({ required: true })
  message?: string;

  @Prop({ required: true })
  type?: string;

  @Prop()
  fade?: number;

  @Watch('fade')
  onFadeChanged(val: number) {
    console.log('fadechanged');
    this.show = true;
    if (val) {
      setTimeout(() => {
        this.show = false;
      }, val);
    }
  }
}












import jsLogger from 'js-logger';
import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import {ISearchResult} from '@/model/search';

const logger = jsLogger.get('cmp.paginator');

if (process.env.NODE_ENV !== 'production') {
  logger.setLevel(jsLogger.DEBUG);
}

@Component
export default
class Paginator extends Vue {
  @Prop({required: true})
  searchResults: ISearchResult;

  @Prop({required: true})
  query: string;

  switchPage(pageNum: number) {
    logger.debug('switch page', pageNum);
    this.$router.push({
      name: 'dashboard-search-query-page',
      params: {
        query: this.query,
        page: String(pageNum)
      }
    });

  }

  get PageNum(): number {
    return  this.searchResults.pageNum;
  }

  get NumPages(): number {
    return this.searchResults.pageCnt;
  }

  *pageRange() {
    // The page range should attempt to have 5 "previous" pages to the left.
    // Given a 20 page set, and the user is on page 3, the numbers returned should be
    // 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11

    // A 20 page set on page 8
    // 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
    let numbersWanted = 11;
    const BUFFER = 5;

    let start = Math.max(this.PageNum - BUFFER, 1);

    for (let x = start; numbersWanted && x <= this.NumPages; x++, numbersWanted--) {
      yield x;
    }
  }
}















import jsLogger from 'js-logger';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { State, Mutation } from 'vuex-class';

const logger = jsLogger.get('cmp.chat');

if (process.env.NODE_ENV !== 'production') {
    logger.setLevel(jsLogger.DEBUG);
}

@Component
export default class Chat extends Vue {

  @State('socketConnected')
  socketConnected: boolean;

  @State('chatEnabled')
  chatEnabled_: boolean;

  @Mutation('updateChatEnabled')
  updateChatEnabled: (chatEnabled: boolean) => void;

  get chatEnabled() {
    return this.chatEnabled_;
  }

  set chatEnabled(val: boolean) {
    this.updateChatEnabled(val);
  }
}

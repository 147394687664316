













import jsLogger from 'js-logger';
import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import IComment from '@/model/discuss/comment';
import {IStatusDictionary} from '@/util/apiresponse';

const logger = jsLogger.get('cmp.reply');

if (process.env.NODE_ENV !== 'production') {
    logger.setLevel(jsLogger.DEBUG);
}

@Component
export default class Reply extends Vue {

  // Callback taking no parameters.
  @Prop({default: null})
  onCancel: () => void;

  // Callback taking one parameter an IComment instance.
  @Prop({default: null})
  onPosted: (comment: IComment, parentComment: IComment) => void;

  // Parent comment if replying to a comment.
  @Prop({default: null})
  parentComment: IComment;

  // Comment being edited, if provided puts the component into edit mode.
  @Prop({default: null})
  editComment: IComment;

  // Question id to use.
  @Prop()
  questionId: number;

  replyText = '';
  errorMessage = '';
  haveReplied = false;
  isBusy = false;

  mounted() {
    if (this.editComment) {
      this.replyText = this.editComment.comment;
    }
  }

  async post() {
    if (this.replyText.length < 5) {
      this.errorMessage = 'Replies must be at least 5 characters long.';
      return;
    }

    this.errorMessage = '';
    try {
      let res;

      if (this.editComment) {
        res = await this.$store.state.axios.post(
          `/api/discuss/question/edit/${this.editComment.id}`, {
            c: this.replyText
        });
      } else if (this.parentComment) {
        res = await this.$store.state.axios.post(
          `/api/discuss/question/reply/${this.parentComment.id}`, {
            c: this.replyText
        });
      } else {
        res = await this.$store.state.axios.post(
          `/api/discuss/question/${this.questionId}`, {
            c: this.replyText
        });
      }

      res = res.data as IStatusDictionary;
      logger.info('PostComment response', res);

      if (!res.status) {
        this.errorMessage = res.error_msg;
      } else {
        this.haveReplied = true;
        this.replyText = '';
        this.posted(res.value);
      }
    } catch (e) {
      this.errorMessage = 'Unexpected error posting your reply.  Please try later.';
      logger.error('Error posting reply', e);
    } finally {
      this.isBusy = false;
    }
  }

  cancel() {
    return this.onCancel ? this.onCancel() : null;
  }

  posted(comment: IComment) {
    return this.onPosted ? this.onPosted(comment, this.parentComment) : null;
  }
}

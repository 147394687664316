

























import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { State, Mutation } from 'vuex-class';
import * as Logger from 'js-logger';
import { User } from '@/model/user';
import {
  HierarchyLevel
} from '@/model/hierarchy';
import HierarchyConfigurator from '@/components/dashboard/study/test/hierarchy-configurator.vue';
import { IStatusDictionary } from '@/util/apiresponse';
import { IHierarchyState } from '@/store/modules/hierarchy';
import { QueuedStudyguideCreation, StudyguideDownload } from '@/model/notification';
import { INotificationState } from '@/store/modules/notification';
import { Location } from 'vue-router';
import store from '@/store';

const log = Logger.get('sg-create');

interface IResult {
  success: boolean;
  errorMsg: string;
}

export interface IMakeStudyguideResult extends IResult {
  location: string;
  trackingDict: QueuedStudyguideCreation;
}

export interface IMonitorStudyguideCreateResult extends IResult {
  studyguideDownload: StudyguideDownload | undefined;
}

export interface IPurchaseStudyguideCookie {
  sgtype: string;
  sgid: number;
  sgname: string;
}

/**
 * Create a studyguide.
 *
 * Create a studyguide and return the tracking dictionary / location.
 *
 * @param type: type of studyguide.
 * @param studyguideId: Id of the studyguide.
 * @returns IMakeStudygyuideResult instance.
 */
export async function makeStudyguide(type: string, studyguideId: string | number):
    Promise<IMakeStudyguideResult> {
  let res = await store.state.axios.get(
      `/api/studyguide/make/${type}/${studyguideId}`);
  const data = res.data as IStatusDictionary;

  return {
    success: data.status !== 0,
    errorMsg: data.error_msg || '',
    location: data.location,
    trackingDict: data.tracking_dict
  };
}

/**
 * Monitor studyguide creation.
 *
 * When the studyguide has been successfully created, return a
 * IMonitorStudyguideCreateResult instance.
 */
export async function monitorStudyguideCreation(studyguideName: string, location: string):
    Promise<IMonitorStudyguideCreateResult> {

  // Cool, modern way to sleep in javascript.
  function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  // The promise to return.
  const monitorResult: IMonitorStudyguideCreateResult = {
    success: false,
    errorMsg: '',
    studyguideDownload: undefined
  };

  // Loop until results are fetched, then callback with resolve.
  let tryCount: number = 0;

  while (true) {
    tryCount++;
    const result = await store.state.axios.get(location);
    const checkRes = result.data as IStatusDictionary;

    if (tryCount === 40) {
      log.error('Time out waiting for studyguide to finish.');
      monitorResult.errorMsg = 'Timeout waiting on studyguide to be created.';
      return monitorResult;
    }

    if (checkRes.status === 0) {
      log.error(checkRes);
      monitorResult.errorMsg = checkRes.error_msg || 'Unexpected error monitoring studyguide creation.';
      return monitorResult;
    }

    console.log(checkRes);

    switch (checkRes.sg_status.status) {
      case 'QUEUED': {
        await sleep(3000);
        break;
      }

      case 'FINISHED': {
        const sgDownload = new StudyguideDownload();
        sgDownload.name = studyguideName;
        sgDownload.downloadUrl = checkRes.sg_status.studyguide_url;
        sgDownload.graphicBookUrl = checkRes.sg_status.graphicbook_url;
        monitorResult.studyguideDownload = sgDownload;
        monitorResult.success = true;
        return monitorResult;
      }

      case 'UNKNOWN': {
        monitorResult.errorMsg = 'Studyguide unknown.';
        monitorResult.success = false;
        return monitorResult;
      }
    }
  }
}

@Component({
  components: { HierarchyConfigurator }
})
export default class StudyguideCreate extends Vue {
  hierarchyLevel = HierarchyLevel.Subject;

  @State('hierarchy')
  hierarchy: IHierarchyState;

  @State(state => state.serverConfig.exam_max_questions)
  maxNumberQuestions: number;

  @State('user')
  user: User;

  @State('notification')
  notification: INotificationState;

  @Mutation('notification/setQueuedStudyguideCreation')
  setQueuedStudyguideCreation: any;

  @Mutation('notification/addQueuedStudyguideDownload')
  addQueuedStudyguideDownload: any;

  @Mutation('user/updateUser')
  updateUser: any;

  errorMsg: string = '';

  amPreparingPayment = false;

  // Create the studyguide, or setup a purchase.
  async onCreate() {
    let type;
    let studyguideId;
    let studyguideName = this.hierarchy.areaSelected!.name;

    if (this.hierarchy.subjectSelected!.id === 'any') {
      type = 'a';
      studyguideId = this.hierarchy.areaSelected!.id;
    } else {
      type = 's';
      studyguideName = `${studyguideName} / ${this.hierarchy.subjectSelected!.name}`;
      studyguideId = this.hierarchy.subjectSelected!.id;
    }

    if (this.user.studyguideTokens) {
      await this.makeStudyguide(type, studyguideId, studyguideName);
    } else {
      await this.purchaseStudyguide(type, studyguideId, studyguideName);
    }
  }

  async makeStudyguide(type: string, studyguideId: string | number, studyguideName: string) {
    const result = await makeStudyguide(type, studyguideId);
    if (!result.success) {
      log.error(result.errorMsg);
      this.errorMsg = result.errorMsg;
      return;
    }

    this.setQueuedStudyguideCreation(result.trackingDict);
    log.info('Setting queued studyguide creation =>', result.trackingDict);
    const monitorResult = await monitorStudyguideCreation(studyguideName, result.location);
    if (!monitorResult.success) {
      log.error(monitorResult.errorMsg);
      this.errorMsg = monitorResult.errorMsg;
      return;
    }

    this.setQueuedStudyguideCreation(null);
    this.addQueuedStudyguideDownload(monitorResult.studyguideDownload);

    // Studyguide created successfully, update the user token count.
    const user = Object.assign({}, this.user);
    user.studyguideTokens--;
    this.updateUser(user);
  }

  async purchaseStudyguide(type: string, studyguideId: string | number,
                           studyguideName: string) {
    const baseUrl = location.protocol + '//' + location.host + '/';

    const cancelUrl = baseUrl + '#' + this.$route.path;
    const returnUrl = baseUrl + this.$router.resolve({
      name: 'store-confirm-purchase-studyguide'
    } as Location).href;

    this.$cookies.set('purchase-studyguide', JSON.stringify({
        sgtype: type,
        sgid: studyguideId,
        sgname: studyguideName
    } as IPurchaseStudyguideCookie));

    this.amPreparingPayment = true;
    try {
      let res = await this.$store.state.axios.post(
            '/api/store/studyguide/create-payment/',
            {
              type_: type,
              studyguide_id: studyguideId,
              return_url: returnUrl,
              cancel_url: cancelUrl
            });
      res = res.data as IStatusDictionary;
      if (res.status) {
        window.location.href = res.approval_url;
      } else {
        this.errorMsg = res.error_msg || 'Unexpected error occurred, please try later';
      }
    } catch (e) {
      this.errorMsg = 'Unexpected error, please try later.';
      console.log('Error setting up payment', e);
      this.amPreparingPayment = false;
    }
  }

  get buyMessage() {
    if (this.user.studyguideTokens > 0) {
      return '';
    }

    if (this.hierarchy.subjectSelected) {
      let price = this.hierarchy.subjectSelected.id === 'any' ?
        this.$store.state.serverConfig.studyguide_price_area :
        this.$store.state.serverConfig.studyguide_price_subject;

      return 'Your current studyguide configuration is $' + price +
          '.  Subscriptions also provide studyguide tokens which ' +
          'allow you to create studyguides even after your subscription ' +
          'has expired.';
    }

    return '';
  }
}

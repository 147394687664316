


















import jsLogger from 'js-logger';
import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import {ISearchResult} from '@/model/search';
import Answer from '@/model/quizHierarchy/answer';

const logger = jsLogger.get('cmp.search-results');

if (process.env.NODE_ENV !== 'production') {
    logger.setLevel(jsLogger.DEBUG);
}

@Component
export default
class SearchResults extends Vue {
  @Prop({required: true})
  searchResults: ISearchResult;

  getQAClass(index) {
    return index % 2 ? 'title lightgrey' : 'title';
  }

  getCorrectAnswerText(answers: Answer[]): string {
    for (const x of answers.keys()) {
      if (answers[x].IsCorrect) {
        return this.highlightKeywords(answers[x].AnswerText);
      }
    }

    return 'Correct answer not set.';
  }

  highlightKeywords(text: string) {
    for (const keyword of this.searchResults.searchKeywords) {
      const replaceText = `<strong>${keyword}</strong>`;
      text = text.replace(keyword, replaceText);
    }
    return text;
  }
}

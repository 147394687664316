







import jsLogger from 'js-logger';
import store from '../store';
import Component from 'vue-class-component';
import Vue from 'vue';
import { Route } from 'vue-router';
import NotValidatedDlg from '@/components/dashboard/NotValidatedDlg.vue';
import DashboardNotifications from './dashboard-notifications.vue';

const logger = jsLogger.get('dashboard');

// Register the router hooks with their names
Component.registerHooks([
  'beforeRouteEnter'
]);

@Component({
    components: {
        NotValidatedDlg,
        DashboardNotifications
    }
})
export default class Dashboard extends Vue {
    async beforeRouteEnter(to: Route, from: Route, next: (x?: any) => void) {
        // called before the route that renders this component is confirmed.
        // does NOT have access to `this` component instance,
        // because it has not been created yet when this guard is called!
        logger.debug('Checking route.');

        let vue = new Vue();
        let user = store.state.user;
        if (user.emailAddress == null) {
          logger.debug('user email address is null');
          // Load the user from cookies if available.
          if (vue.$cookies.isKey('userd')) {
            try {
              user = JSON.parse(vue.$cookies.get('userd'));
              const configd = JSON.parse(vue.$cookies.get('configd'));
              store.commit('user/updateUser', user);
              store.commit('updateServerConfig', configd);
              next();
            } catch (err) {
              logger.error('Error decoding userd.');
              next('/');
            }
          }
        } else {
          next();
        }
    }
}

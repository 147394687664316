export interface IChat {
  id: number;
  userName: string;
  date: string;
  text: string;
}

export interface IBeautifulMessage {
  author: string;
  type: string;
  id: number;
  isEdited: boolean;
  data: {
    text: string;
    meta?: string | undefined;
  };
}


export function chatToBeautifulMessage(chat: IChat, userName: string): IBeautifulMessage {
  return {
    author: chat.userName === userName ? 'me' : chat.userName,
    type: 'text',
    id: chat.id,
    isEdited: false,
    data: {
      text: chat.text,
      meta: chat.date
    }
  };
}














































import jsLogger from 'js-logger';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import QuestionAndAnswer from '@/components/discuss/question-and-answer.vue';
import CommentDiscuss from '@/components/discuss/comment.vue';
import Reply from '@/components/discuss/reply-or-edit.vue';
import IComment from '@/model/discuss/comment';
import GraphicsTab from '@/components/dashboard/study/test/GraphicsTab.vue';
import AssetsTab from '@/components/dashboard/study/test/assets-tab.vue';
import bus from '@/util/eventbus';
import { Mutation } from 'vuex-class';
import {IParentChildComment} from '@/store/modules/question-discussion';

const logger = jsLogger.get('cmp.discuss-question');

if (process.env.NODE_ENV !== 'production') {
    logger.setLevel(jsLogger.DEBUG);
}

@Component({
  components: {
    Reply,
    QuestionAndAnswer,
    CommentDiscuss,
    GraphicsTab,
    AssetsTab
  }
})
export default class DiscussQuestion extends Vue {

  amPostingReply: boolean = false;

  @Prop({default: false})
  embeddedMode: boolean;

  @Mutation('questionDiscuss/commentNew')
  commentNew: (commentPayload: IParentChildComment) => void;

  onPosted(comment: IComment, parent: IComment) {
    // this.syncedQuestion.comments.push(comment);
    this.commentNew({comment, parent});
    this.postingReply = false;
  }

  get question() {
    return this.$store.state.questionDiscuss.question;
  }

  get hasGraphics() {
    return this.question.graphics.length > 0;
  }

  get hasAssets() {
    return this.question.assets.length > 0;
  }

  get hasReference() {
    return this.question.reference !== null;
  }

  // Return applicable tab items for this question.
  get tabItems() {
    if (!this.question) {
      return [];
    }

    let items: string[] = [];
    items.push('Discuss');

    if (this.hasGraphics) {
      items.push('Graphics');
    }

    if (this.hasAssets) {
      items.push('Assets');
    }

    if (this.hasReference) {
      items.push('Reference');
    }

    return items;
  }

  get postingReply() {
    return this.amPostingReply;
  }

  set postingReply(val: boolean) {
    this.amPostingReply = val;
    bus.$emit('amPostingOrEditingReply', val);
  }
}

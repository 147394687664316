import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { User } from '@/model/user';
import store from '@/store';

@Component
export default class BaseCardMixin extends Vue {
  @Prop({default: () => new User(store.state.user)})
  user: User;

  @Prop({default: () => store.state.serverConfig})
  serverConfig: any;
}

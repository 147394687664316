
































// Show a dialog whenever the reportBugDlg store is true.
import Vue from 'vue';
import Component from 'vue-class-component';
import { Mutation, State } from 'vuex-class';
import { IStatusDictionary } from '@/util/apiresponse';
import * as Logger from 'js-logger';

// tslint:disable-next-line:no-var-requires
const promised = require('vue-promised');

const logger = Logger.get('report-bug-dlg');

const info = {

    timeOpened: new Date(),
    timezone: (new Date()).getTimezoneOffset() / 60,

    url() { return window.location.href; },
    browserName() { return navigator.appName; },
    browserEngine() { return navigator.product; },

  /*
   * Maybe one day these will be needed.
    pageon() { return window.location.pathname; },
    referrer() { return document.referrer; },
    previousSites() {return history.length; },
    browserVersion1a() { return navigator.appVersion; },
    browserVersion1b() { return navigator.userAgent; },
    browserLanguage() { return navigator.language; },
    browserOnline() { return navigator.onLine; },
    browserPlatform() { return navigator.platform; },
    javaEnabled() { return navigator.javaEnabled(); },
    dataCookiesEnabled() { return navigator.cookieEnabled; },
    dataCookies1() { return document.cookie; },
    dataStorage() { return localStorage; },
    sizeScreenW() { return screen.width; },
    sizeScreenH() { return screen.height; },
    sizeDocW() { return document.body.clientWidth; },
    sizeDocH() { return document.body.clientHeight; },
    sizeInW() { return innerWidth; },
    sizeInH() { return innerHeight; },
    sizeAvailW() { return screen.availWidth; },
    sizeAvailH() { return screen.availHeight; },
    scrColorDepth() { return screen.colorDepth; },
    scrPixelDepth() { return screen.pixelDepth; },
   */
};

const SUCCESSFUL_SUBMIT_MSG = 'Bug report submitted successfully, thanks!';
const DEFAULT_TITLE = 'Report A Bug';

@Component(
  {
    components: { Promised: promised.Promised }
  }
)
export default class ReportBugDlg extends Vue {
  title = DEFAULT_TITLE;
  description = '';
  subject = '';
  updating: Promise<IStatusDictionary> | null = null;
  amPostingBugReport = false;
  bugReportPostedSuccessfully = false;
  successfulSubmitMsg = SUCCESSFUL_SUBMIT_MSG;

  @Mutation('updateShowReportBugDlg') updateShowReportBugDlg: any;
  @State('showReportBugDlg') showReportBugDlg: any | null;

  mounted() {
    this.description = '';
    this.subject = '';

    const showReportBugDlg = this.showReportBugDlg;

    if (showReportBugDlg && typeof showReportBugDlg === 'object') {
      if (showReportBugDlg.dialogPrefills) {
        this.description = showReportBugDlg.dialogPrefills.description || '';
        this.subject = showReportBugDlg.dialogPrefills.subject || '';
        this.successfulSubmitMsg = showReportBugDlg.dialogPrefills.successfulSubmitMsg || SUCCESSFUL_SUBMIT_MSG;
        this.title = showReportBugDlg.dialogPrefills.title || DEFAULT_TITLE;
        delete showReportBugDlg.dialogPrefills;
      }
    }

    this.updating = null;
  }

  cancel() {
    this.updateShowReportBugDlg(null);
  }

  async submitBugReport() {
    try {
      const isValid = await this.$validator.validateAll();
      if (!isValid) {
        return;
      }

      this.amPostingBugReport = true;
      logger.debug('Submitting bug report ', this.subject, this.description,
                   this.makeDebugInfo());

      this.updating = this.postBugReport();
      const res = (await this.updating) as IStatusDictionary;

      logger.debug('Bug report submission response: ', res);
      if (res.status === 1) {
        this.bugReportPostedSuccessfully = true;
      }
    } finally {
      this.amPostingBugReport = false;
    }
  }

  async postBugReport(): Promise<IStatusDictionary> {
    let res = await this.$store.state.axios.post('/api/user/bug-report', {
      subject: this.subject,
      msg: this.description,
      info: this.makeDebugInfo()
    });
    return res.data as IStatusDictionary;
  }

  makeDebugInfo() {
    return {
      url: info.url(),
      browser: info.browserName(),
      browserEngine: info.browserEngine(),
      additionalInfo: this.showReportBugDlg || 'No additional info.'
    };
  }

  get showDialog() {
    return this.showReportBugDlg;
  }

  set showDialog(_ignore) {
    // Ignore this being set
  }
}

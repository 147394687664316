











import jsLogger from 'js-logger';
import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';

const logger = jsLogger.get('cmp.discuss.question');

if (process.env.NODE_ENV !== 'production') {
  logger.setLevel(jsLogger.DEBUG);
}

@Component
export default class QuestionAndAnswer extends Vue {

  @Prop({default: ''})
  answerText: string;

  @Prop({required: true})
  questionText;
}



















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import QuestionTracker from '@/model/quizHierarchy/question-tracker';

@Component
export default class QuestionTrackerView extends Vue {
  @Prop(QuestionTracker) questionTracker: QuestionTracker;
  @Prop(Boolean) isUserTracker: boolean;
  @Prop({default: false}) incrementMissCount: boolean;

  get person() {
    if (this.isUserTracker) {
      return 'you';
    }
    return 'all users';
  }

  get missCount() {
    return this.incrementMissCount ?
      this.questionTracker.MissCount + 1 : this.questionTracker.MissCount;
  }

  get icon() {
    return this.isUserTracker ? 'mdi-face' : 'mdi-laptop';
  }
}






















import Component from 'vue-class-component';
import Vue from 'vue';
import {ApiCallFailed} from '@/util/apicallfailed';

enum ValidationStatus {
  Fail,
  Success,
  Pending
}

@Component
export default class ValidateEmail extends Vue {

  validationStatus = ValidationStatus.Pending;
  errorMessage = '';
  firstName = '';

  get validationPending() {
    return this.validationStatus === ValidationStatus.Pending;
  }

  get validationError() {
    return this.validationStatus === ValidationStatus.Fail;
  }

  get validationSuccess() {
    return this.validationStatus === ValidationStatus.Success;
  }

  async created() {
    try {
      let firstName: string = await this.$api.get(
        `/api/user/validate-email/${this.$route.params.uid}/${this.$route.params.hash}`);
      this.validationStatus = ValidationStatus.Success;
    } catch (e) {
      if (e instanceof ApiCallFailed) {
        // TODO: handle exception.
        this.errorMessage = e.message;
      } else {
        this.errorMessage = 'Unexpected error validating your email.';
      }
      this.validationStatus = ValidationStatus.Fail;
      console.log('validation error: ', e);
    }
  }
}






















import {Watch, Component} from 'vue-property-decorator';
import Vue from 'vue';
import { State } from 'vuex-class';
import { INotificationState } from '@/store/modules/notification';
import DownloadStudyguideDialog from '@/components/notifications/download-study-guide.vue';

const TOOLTIP_FADE_DELAY = 3000;

@Component(
  {
    components: {
      DownloadStudyguideDialog
  }
})
export default class DashboardNotifications extends Vue {

  // When activated, this tooltip disappears after a few seconds.
  queuedStudyguideTooltip: boolean = false;
  studyguideDownloadTooltip: boolean = false;

  @State(state => state.notification) notification: INotificationState;

  showDownloadDialog: boolean = false;

  @Watch('notification.studyguideDownloads.length')
  onStudyguideDownloadsLengthChanged(newVal: any) {
    this.studyguideDownloadTooltip = newVal > 0;

    setTimeout(() => {
      this.studyguideDownloadTooltip = false;
    }, TOOLTIP_FADE_DELAY);
  }

  @Watch('notification.queuedStudyguideCreation')
  onQueuedStudyguideCreation(newVal: any) {
    this.queuedStudyguideTooltip = newVal != null;

    setTimeout(() => {
      this.queuedStudyguideTooltip = false;
    }, TOOLTIP_FADE_DELAY);
  }

}

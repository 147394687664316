





import Vue from 'vue';
import Component from 'vue-class-component';
import * as Logger from 'js-logger';
import {Prop} from 'vue-property-decorator';
import Question from '@/model/quizHierarchy/question';

const log = Logger.get('MissedQuestionWarning');
const MISSED_QUESTION_WARNING_THRESHOLD = 0.60;

@Component
export default class MissedQuestionWarning extends Vue {
  @Prop({required: true})
  question: Question;

  @Prop({default: MISSED_QUESTION_WARNING_THRESHOLD})
  missedQuestionWarningThreshold: number;

  get missedAlotWarning() {
    const sysQT = this.question.SysQuestionTracker;
    const sysQTtotalCnt = sysQT.MissCount + sysQT.CorrectCount;
    const sysMiss = sysQT.MissCount / sysQTtotalCnt;

    const usrQT = this.question.UserQuestionTracker;
    const usrQTtotalCnt = usrQT.MissCount + usrQT.CorrectCount;
    const usrMiss = usrQT.MissCount / usrQTtotalCnt;

    const sysWideWarning = `This question is missed ${Math.floor(sysMiss * 100)}% of the time system wide.`;
    const usrWarning = `You miss the question ${Math.floor(usrMiss * 100)}% of the time.`;

    if (sysMiss >= MISSED_QUESTION_WARNING_THRESHOLD) {
      if (usrMiss >= MISSED_QUESTION_WARNING_THRESHOLD) {
        return 'Beware! ' + sysWideWarning + usrWarning;
      }
      return 'Beware! ' + sysWideWarning;
    }

    if (usrMiss >= MISSED_QUESTION_WARNING_THRESHOLD) {
      return 'Beware! ' + usrWarning;
    }

    return '';
  }
}

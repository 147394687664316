































import jsLogger from 'js-logger';
import * as Logger from 'js-logger';
import Vue from 'vue';
import {Component, Watch} from 'vue-property-decorator';
import IComment from '@/model/discuss/comment';
import {ApiCallFailed} from '../../util/apicallfailed';
import Paginator, {IPageable} from '@/components/paginator.vue';
import {Route} from 'vue-router';

const logger = Logger.get('cmp.chat');

if (process.env.NODE_ENV !== 'production') {
    logger.setLevel(jsLogger.DEBUG);
}

interface IQuestionComment extends IComment {
  question: string;
  questionId: number;
}

interface IRecentQuestionComments {
  numberOfPages: number;
  comments: IQuestionComment[];
}

@Component({
  components: { Paginator }
})
export default class DiscussIndex extends Vue {

  parentComments: IQuestionComment[] = [];
  numberOfPages = 1;
  currentPage = 1;

  @Watch('$route')
  async handleRouteChange(to: Route) {
    logger.debug('Search route updated', to);
    await this.handleSearchParams();
  }

  async mounted() {
    await this.handleSearchParams();
  }

  get pageable() {
    return {
      currentPage: this.currentPage,
      numPages: this.numberOfPages
    } as IPageable;
  }

  async fetchComments() {
    try {
      const val = await this.$api.get('/api/discuss/question', {
          params: {
            items_per_page: 10,
            page: this.currentPage
          }
        }) as IRecentQuestionComments;
      this.parentComments = val.comments;
      this.numberOfPages = val.numberOfPages;
    } catch (e) {
      if (e instanceof ApiCallFailed) {
        console.log('error', e);
      }
    }
  }

  async handleSearchParams() {
    this.parentComments = [];
    this.currentPage = Number(this.$route.params.page) || 1;
    await this.fetchComments();
  }

  openComment(comment: IQuestionComment) {
    this.$router.push({
      name: 'dashboard-discuss',
      params: {
        qid: comment.questionId.toString()
      }
    });
  }
}

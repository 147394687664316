


































import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import {Mutation, State} from 'vuex-class';
import IExamConfiguration from '@/model/exam-configuration';
import { IStatusDictionary } from '@/util/apiresponse';
import * as Logger from 'js-logger';
import { User } from '@/model/user';
import QuizHierarchy from '@/model/quizHierarchy';

const log = Logger.get('precfg-exam-select');

@Component
export default class SelectPreconfiguredExam extends Vue {
  amBusy = '';

  errorMessage: string | undefined = '';

  examConfigurations: IExamConfiguration[] = [];

  selectedExamConfig: IExamConfiguration = {} as IExamConfiguration;

  @State('user')
  user: User;

  @Mutation('user/updateUserAttributes')
  updateUserAttributes: any;

  async mounted() {
    this.amBusy = 'One second, fetching exam configurations...';
    try {
      let res = await this.$store.state.axios.get('/api/test/finalexam/configuration');
      res = res.data as IStatusDictionary;
      this.examConfigurations.splice(0, this.examConfigurations.length);
      if (res.status === 0) {
        throw new Error(res.error_msg);
      }
      const examConfigs = (res.value as IExamConfiguration[]);
      examConfigs.sort((a, b) => {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      });
      examConfigs.forEach(element => {
        this.examConfigurations.push(element);
      });
      this.selectedExamConfig = examConfigs[0];
    } catch (error) {
      log.error(error);
      this.errorMessage = error.message;
    } finally {
      this.amBusy = '';
    }
  }

  async takeExam() {
    if (!this.user.finalExamTokens) {
      this.$cookies.set('postPurchase', 'dashboard-study-preconfigured-exam');
      this.$router.push({name: 'store-final-exam-buy'});
    } else {
      log.info('Taking exam for ', this.selectedExamConfig);

      try {
        this.amBusy = 'Fetching your final exam...';

        let res = await this.$store.state.axios.get(
          `/api/test/finalexam/take-preconfigured/${this.selectedExamConfig.id}`);
        res = res.data as IStatusDictionary;
        log.info('Fetched ', res);

        if (res.status) {
          // Final exam created successfully, update the user token count.
          this.updateUserAttributes({finalExamTokens: this.user.finalExamTokens - 1});

          this.$store.commit('takeTest/updateQuizHierarchy',
                             QuizHierarchy.fromSSQuizHierarchy(res.value.QuizHierarchy));
          this.$router.push({name: 'dashboard-study-take-test'});
        } else {
          this.errorMessage = res.error_msg || 'Unexpected error occurred.';
        }
      } catch (e) {
        this.errorMessage = 'Sorry, could not create exam.  If this continues, please ' +
          'contact us.';
      } finally {
        this.amBusy = '';
      }
    }
  }
}

import HierarchyNode from './hierarchynode';
import Question from './question';
import Score from './score';

export default class QuizHierarchy {
  /**
   * Convert a QuizHierarchy object fetched from seasources into a typescript
   * QuizHierarchy.
   *
   * @param object QuizHierarchy fetched from seasources.
   */
  static fromSSQuizHierarchy(object: any): QuizHierarchy {
    const quizHierarchy = new QuizHierarchy();

    Object.assign(quizHierarchy, object);

    // Convert each question.
    quizHierarchy.Questions = quizHierarchy.Questions.map(Question.fromSSQuestion);

    return quizHierarchy;
  }

  public IsRetakeOfMissedQuestions: boolean;
  public IsFinalExam: boolean;
  public IsRandom: boolean;
  public Section?: number;
  public AreaCategory: HierarchyNode;
  public Area: HierarchyNode;
  public Subject: HierarchyNode;
  public Topic: HierarchyNode;
  public Subtopic: HierarchyNode;
  public CurrentQuestion: number;
  public Questions: Question[];
  public PreviousScores: Score[];
  public FinalExamId?: number;
  public NextSection: boolean;
}

// Module for maintaining the user.
import {IUserDictionaryModel} from '@/model/user';
import { IState } from '../state';
import { Module, Mutation, MutationTree } from 'vuex';
import {cloneDeep} from 'lodash-es';

const debug = process.env.NODE_ENV !== 'production';

export const UserDictionaryModelState: IUserDictionaryModel = {
    firstName: '',
    middleName: '',
    lastName: '',
    emailAddress: '',
    password: undefined,
    passwordValidate: undefined,
    currentLicense: '',
    desiredLicense: '',
    comments: '',
    id: 0,
    finalExamTokens: 0,
    finalExams: [],
    roles: [],
    scores: [],
    scoreCount: 0,
    studyguideTokens: 0,
    subscriptionLevel: '',
    todaysTestCount: 0,
    verified: false,
    ticket: '',
    chatName: '',
    options: {},
    bbHash: ''
};

// mutations
interface IUserMutation<S> extends MutationTree<S> {
    updateUser: Mutation<S>;
    updateUserAttributes: Mutation<S>;
}

const mutations: IUserMutation<IUserDictionaryModel> = {
    // commit('user/updateUser')
    updateUser(state: IUserDictionaryModel, user: IUserDictionaryModel) {
        user = cloneDeep(user);
        Object.assign(state, user);
    },
    updateUserAttributes(state: IUserDictionaryModel, attributes: any) {
        // Use this mutation to change parts of the user object.  For
        // instance this will update the first and last name.
        // commit('user/updateUserAttributes', {firstName: 'first', lastName: 'last'})
        Object.assign(state, cloneDeep(attributes));
    }
};

/**
 * Create a user module.
 *
 * @param state initial state for the user module.
 */
export function createModule(state: IUserDictionaryModel | null = null): Module<IUserDictionaryModel, IState> {
    if (state === null) {
        state = Object.assign({}, UserDictionaryModelState);
    }

    return {
        namespaced: true,
        state,
        mutations,
        strict: debug
    } as Module<IUserDictionaryModel, IState>;
}

// Module
export default createModule();

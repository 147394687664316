import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/es5/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.indigo.base,
        secondary: colors.blueGrey.base,
        accent: colors.deepPurple.base,
        error: colors.red.base,
        warning: colors.amber.base,
        info: colors.blue.base,
        success: colors.green.base
      }
    }
  },
  icons: {
    iconfont: 'mdi',
  },
});























  import Vue from 'vue';
  import {sum} from 'lodash-es';
  import {State} from 'vuex-class';
  import {Component, Prop} from 'vue-property-decorator';
  import ScoreChart from '@/components/score/score-chart.vue';
  import Question from '@/model/quizHierarchy/question';
  import QuizHierarchy from '@/model/quizHierarchy';
  import QuestionInfo from '@/components/dashboard/study/test/question-info.vue';
  import {User} from '@/model/user';

  @Component(
  {
    components: {
      ScoreChart,
      QuestionInfo
    }
  }
)
export default class QuizInfo extends Vue {
  @State('user') user: User;
  @Prop(Question) question: Question;
  @Prop(QuizHierarchy) quizHierarchy: QuizHierarchy;

  get quizHierarchyAsString() {
    const qh = this.quizHierarchy;
    return `${qh.AreaCategory.Name} / ${qh.Area.Name} / ${qh.Subject.Name} / ${qh.Topic.Name} / ${qh.Subtopic.Name}`;
  }

  get numTimesTestTakenPreviously() {
    return this.quizHierarchy.PreviousScores.length;
  }

  get averageScore() {
    const total = sum(this.quizHierarchy.PreviousScores.map((score) => score.Score));
    return Math.round(total / this.quizHierarchy.PreviousScores.length);
  }

}
















import jsLogger from 'js-logger';
import Vue from 'vue';
import { State, Mutation } from 'vuex-class';
import {Component} from 'vue-property-decorator';

const logger = jsLogger.get('cmp.AppNavDrawer');

if (process.env.NODE_ENV !== 'production') {
    logger.setLevel(jsLogger.DEBUG);
}

@Component
export default
class AppNavDrawer extends Vue {

  @State('appDrawer')
  _appDrawer: boolean;

  @Mutation('updateAppDrawer')
  updateAppDrawer: (appDrawer: boolean) => void;

  @Mutation('updateShowReportBugDlg')
  updateShowReportBugDlg: (config: any) => void;

  items = [
    {icon: 'mdi-home', title: 'Home'},
    {icon: 'mdi-database-search', title: 'Search'},
    {icon: 'mdi-school', title: 'Study'},
    {icon: 'mdi-store', title: 'Store'},
    {icon: 'mdi-poll', title: 'Scores'},
    {icon: 'mdi-message', title: 'Chat'},
    {icon: 'mdi-forum', title: 'Discuss Questions'},
    {icon: 'mdi-email', title: 'Contact Us'},
    {icon: 'mdi-logout', title: 'Logout'}
  ];

  push(name: string) {
    if (name === 'Study') {
      this.$router.push({ name: 'dashboard-study' });
    } else if (name === 'Home') {
      this.$router.push({ name: 'dashboard-home' });
    } else if (name === 'Search') {
      this.$router.push({name: 'dashboard-search'});
    } else if (name === 'Scores') {
      this.$router.push({name: 'dashboard-scores'});
    } else if (name === 'Store') {
      this.$router.push({name: 'store'});
    } else if (name === 'Chat') {
      this.$router.push({name: 'chat'});
    } else if (name === 'Contact Us') {
      this.updateShowReportBugDlg({
        dialogPrefills: {
          title: 'Contact Us',
          subject: '',
          description: '',
          successfulSubmitMsg: 'Thank you for contacting us, if necessary we will respond via email.'
        }
      });
    } else if (name === 'Logout') {
      this.$cookies.remove('stayLoggedIn');
      this.$cookies.remove('un');
      this.$cookies.remove('pw');
      window.location.replace('/');
    } else if (name === 'Discuss Questions') {
      this.$router.push({ name: 'dashboard-discuss-index'});
    }

    logger.debug('Push Menu Nav ' + name);
    this.updateAppDrawer(false);
  }

  onInputChanged(newval: boolean) {
    // Synch up the app drawer in case the user clicked outside the navbar.
    this.updateAppDrawer(newval);
  }

  get appDrawer() {
    return this._appDrawer;
  }

  set appDrawer(appDrawer: boolean) {
    this.updateAppDrawer(appDrawer);
  }
}


















import Component from 'vue-class-component';
import BaseCard from '../BaseCard.vue';
import {ApiCallFailed} from '../../../util/apicallfailed';

interface INewsItem {
  id: string;
  date: string;
  news: string;
}

@Component(
  {
    components: {
      BaseCard
    }
  }
)
export default class News extends BaseCard {

  newsItems: INewsItem[] = [];

  async mounted() {
    try {
      let news = await this.$api.get('/api/news/get', {
            params: {
              items_per_page: 10,
              page: 1
            }
      }) as INewsItem[];

      this.newsItems = news;
    } catch (e) {
       if (e instanceof ApiCallFailed) {
        console.log(e);
        // TODO: handle exception.
       }
    }

  }
}

export interface IHierarchyNode {
    id: number | string;
    name: string;
}

export enum HierarchyLevel {
    AreaCategory,
    Area,
    Subject,
    TopicSubtopic
}

export class TopicSubtopic {
    readonly id: string;
    readonly topicId: string | number;
    readonly subtopicId: string | number;
    readonly name: string;
    readonly questionCount: number;

    constructor(id: string, name: string, questionCount: number) {
        this.id = id;
        this.topicId = id.split('/')[0];
        this.subtopicId = id.split('/')[1];

        if (this.topicId === 'all') {
            this.topicId = -1;
            this.subtopicId = -1;
        } else {
            this.topicId = parseInt(this.topicId, 10);
            this.subtopicId = parseInt(this.subtopicId, 10);
        }

        this.name = name;
        this.questionCount = questionCount;
    }
}











































































import jsLogger from 'js-logger';
import * as Logger from 'js-logger';
import Vue from 'vue';
import {ssDictToUserDict} from '@/model/user';
import store from '../store';
import {Component} from 'vue-property-decorator';
import {ApiCallFailed} from '@/util/apicallfailed';
import {flaskSocketUrl} from '@/main';

const logger = Logger.get('Login');

const AM_DEBUG = process.env.NODE_ENV !== 'production';
if (AM_DEBUG) {
  logger.setLevel(jsLogger.DEBUG);
}

@Component
export default class Login extends Vue {
  // transform-class-properties reactifies class properties as long as the
  // values are not undefined.
  validatingCredentials = false;
  errorMessage = '';
  password = '';
  passwordShow = false;
  email = '';
  stayLoggedIn = false;
  userCount = 0;
  testCount = 0;
  onlineCount = 0;

  // Used by vee validate, i think...
  valid = false;

  async mounted() {
    const un = this.$cookies.get('un');
    const pw = this.$cookies.get('pw');
    const stayLoggedIn = this.$cookies.get('stayLoggedIn') ||
      this.$route.params.path;

    (this.$refs.emailTextField as HTMLElement).focus();

    try {
      const onlineCount = await this.$api.get(flaskSocketUrl + 'count');
      const sysStats = await this.$api.get('/api/user/stats');
      this.onlineCount = onlineCount.loggedInUsers;
      this.testCount = sysStats.testCount;
      this.userCount = sysStats.userCount;
    } catch (e) {
      if (e instanceof ApiCallFailed) {
        console.log('error', e);
      }
    }

    if (un && pw && stayLoggedIn) {
      this.email = un;
      this.password = pw;
      this.stayLoggedIn = true;
      try {
        await this.login();
      } catch (e) {
        logger.error('Error logging in:', e);
      }
    }
  }

  async login() {

    const validateSuccess = await this.$validator.validateAll();
    if (!validateSuccess) {
      return;
    }

    this.errorMessage = '';

    this.validatingCredentials = true;
    try {
      let res = await this.$api.postRaw('token/oauth/token',
        {
          grant_type: 'client_credentials',
          client_id: this.email,
          client_secret: this.password,
          stay_logged_in: this.stayLoggedIn
        });

      // login success.
      logger.debug('Login success.');
      store.commit('updateServerConfig', res.config);
      store.commit('updateJwtToken', res.access_token);
      store.commit('updatePermissions', res.permissions);
      this.$api.setAccessToken(res.access_token);

      this.$cookies.set('configd', JSON.stringify(res.config));
      this.$cookies.set('jwt', res.access_token);

      this.$cookies.set('stayLoggedIn', this.stayLoggedIn);
      this.$cookies.set('un', this.email, { expires: '3M' });
      this.$cookies.set('pw', this.password, { expires: '3M' });

      // Initialize the user object, and update with the latest copy.
      const userd = ssDictToUserDict(await this.$api.get('/api/user/logon-init',
        {withCredentials: true}));
      userd.password = this.password;
      this.$cookies.set('bbHash', userd.bbHash);
      this.$cookies.set('userd', JSON.stringify(userd));
      store.commit('user/updateUser', userd);

      // Keep this socket connect / on event registration together.
      // There were separated by a few lines of code before and weren't
      // working.
      this.$socket.io.opts.query = { token: res.access_token };
      this.$socket.connect();
      this.$socket.on('connect', () => {
        logger.debug('Socket connected, joining chat');
        this.$socket.emit('join', {room: 'chat', username: userd.chatName});
      });
      if (this.$route.params.path) {
        // http://localhost:8080/#/dashboard/store/final-exam/bought
        // ?paymentId=PAY-72848108WF4789924LQCLL5A&token=EC-10050117MN682341A&PayerID=RQXQ5BZHU58JQ
        logger.info('Parameters passed to login, pushing the route.', this.$route);
        await this.$router.push({
          name: this.$route.params.path, query: this.$route.query,
          params: JSON.parse(this.$route.params.params || '{}')
        });
      } else {
        await this.$router.push({
          name: 'dashboard-home'
        });
      }
    } catch (e) {
      logger.error('Login exception.', e);

      if (e instanceof ApiCallFailed) {
        logger.debug('Invalid login:', e);

        if (e.message === 'Unexpected error occurred.') {
          this.errorMessage = 'Error communicating with the server, try again later.';
        } else {
          this.errorMessage = 'Invalid credentials.';
        }
      } else {
        this.errorMessage =
          'Error communicating with the server, try again later.';
        throw e;
      }
    }
    this.validatingCredentials = false;
  }

  register() {
    this.$router.push({ name: 'register' });
  }

  recoverPassword() {
    this.$router.push({ name: 'recoverPassword' });
  }

  get userRegistered() {
    return store.state.registration.userRegistered;
  }

  get fetchingStats() {
    return this.userCount === 0 && this.testCount === 0;
  }

  get shouldShowStats() {
    return this.$vuetify.breakpoint.name !== 'xs' &&
      this.$vuetify.breakpoint.name !== 'sm';
  }
}

//
// Hierarchy store contains code for the quiz hierarchy components.
//

import {ActionContext, ActionTree, Module, Mutation, MutationTree} from 'vuex';
import {IState} from '../state';
import {HierarchyLevel, IHierarchyNode, TopicSubtopic} from '@/model/hierarchy';
import {IPreconfiguredQuizHierarchy} from '@/model/quizHierarchy/preconfiguration';
import {IStatusDictionary} from '@/util/apiresponse';
import {range} from 'lodash-es';
import store from '@/store';
import * as Logger from 'js-logger';

const debug = process.env.NODE_ENV !== 'production';

const log = Logger.get('store-hierarchy');
const SECTION_SIZE = 20;

const AnyElement = {id: 'any', name: 'Any'};

export interface IHierarchyState {
    areaCategorySelected?: IHierarchyNode;
    areaCategories: IHierarchyNode[];
    areaSelected?: IHierarchyNode;
    areas: IHierarchyNode[];
    subjectSelected?: IHierarchyNode;
    subjects: IHierarchyNode[];
    topicSubtopicSelected?: TopicSubtopic;
    topicSubtopics: TopicSubtopic[];

    hierarchyUpdating: boolean;

    // Specifies where `Any` should be added to in the hierarchy category as
    // a choice.  All levels below and the specified level will have 'Any' as
    // an option with `any` as the id.
    anyLevel: HierarchyLevel | null;

    // Specifies the preconfigured hierarchy that should be used to configure
    // the hierarchy if possible.
    preconfiguredQuizHierarchy: IPreconfiguredQuizHierarchy;

    sectionSize: number;

    sections: number[];

    sectionSelected?: number;
}

export const HierarchyState: IHierarchyState = {
    areaCategorySelected: undefined,
    areaCategories: [],
    areaSelected: undefined,
    areas: [],
    subjectSelected: undefined,
    subjects: [],
    topicSubtopicSelected: undefined,
    topicSubtopics: [],
    sectionSelected: undefined,
    hierarchyUpdating: true,
    anyLevel: null,
    preconfiguredQuizHierarchy: {
        QCFG_CAT_ID: -1,
        QCFG_AREA_ID: -1,
        QCFG_SUBJ_ID: -1,
        QCFG_TOP_SUBT_ID: -1,
        QCFG_SECTION_ID: -1,
        IS_RANDOM: false
    } as IPreconfiguredQuizHierarchy,
    sectionSize: SECTION_SIZE,
    sections: []
};

// Mutations
interface IHierarchyMutation<S> extends MutationTree<S> {
    updateAreaCategorySelected: Mutation<S>;
    updateAreaCategories: Mutation<S>;
    updateAreas: Mutation<S>;
    updateAreaSelected: Mutation<S>;
    updateSubjects: Mutation<S>;
    updateSubjectSelected: Mutation<S>;
    updateTopicSubtopics: Mutation<S>;
    updateTopicSubtopicSelected: Mutation<S>;
    updateSectionSelected: Mutation<S>;
    updateAnyLevel: Mutation<S>;
    updatePreconfiguredQuizHierarchy: Mutation<S>;
    updateHierarchyUpdating: Mutation<S>;
    updateSections: Mutation<S>;
}

const mutations: IHierarchyMutation<IHierarchyState> = {
    // commit('hierarchy/updateAreaCategorySelected')
    updateAreaCategorySelected(state: IHierarchyState, node: IHierarchyNode) {
        state.areaCategorySelected = node;
    },

    updateAreaCategories(state: IHierarchyState, areaCategories: IHierarchyNode[]) {
        state.areaCategories = areaCategories;
    },

    updateAreas(state: IHierarchyState, areas: IHierarchyNode[]) {
        log.debug('Areas Updated:', areas);
        state.areas = areas;
    },

    updateAreaSelected(state: IHierarchyState, node: IHierarchyNode) {
        state.areaSelected = node;
    },

    updateSubjects(state: IHierarchyState, subjects: IHierarchyNode[]) {
        log.debug('Setting subjects', subjects);
        state.subjects = subjects;
    },

    updateSubjectSelected(state: IHierarchyState, subject: IHierarchyNode) {
        state.subjectSelected = subject;
    },

    updateTopicSubtopics(state: IHierarchyState, topicSubtopics: TopicSubtopic[]) {
        for (let x = 0; x < topicSubtopics.length; x++) {
            topicSubtopics[x] = new TopicSubtopic(topicSubtopics[x].id, topicSubtopics[x].name,
                                                  topicSubtopics[x].questionCount);
        }

        state.topicSubtopics = topicSubtopics;
    },

    updateTopicSubtopicSelected(state: IHierarchyState, topicSubtopic: TopicSubtopic) {
        state.topicSubtopicSelected = topicSubtopic;

        if (topicSubtopic.questionCount > SECTION_SIZE) {
          let numSections = Math.ceil(topicSubtopic.questionCount / SECTION_SIZE);
          state.sections = range(1, numSections + 1);
        }
    },

    updateHierarchyUpdating(state: IHierarchyState, newState: boolean) {
        state.hierarchyUpdating = newState;
    },

    updateSectionSelected(state: IHierarchyState, sectionSelected: number | undefined) {
        state.sectionSelected = sectionSelected;
    },

    updateAnyLevel(state: IHierarchyState, anyLevel: HierarchyLevel) {
        state.anyLevel = anyLevel;
    },

    updatePreconfiguredQuizHierarchy(state: IHierarchyState, preconfiguredQuizHierarchy: IPreconfiguredQuizHierarchy) {
        state.preconfiguredQuizHierarchy = preconfiguredQuizHierarchy;
    },

    updateSections(state: IHierarchyState, sections: number[]) {
        state.sections = sections;
    }
};

// Actions are similar to mutations, the differences being that:
//   - Instead of mutating the state, actions commit mutations.
//   - Actions can contain arbitrary asynchronous operations.
//
interface IHierarchyActionTree<S, R> extends ActionTree<S, R> {
    getAreaCategories(context: ActionContext<S, R>, areaOfStudy: number | null): Promise<IStatusDictionary>;
    getAreas(context: ActionContext<S, R>, areaCategoryId: number): Promise<IStatusDictionary>;
    getSubjects(context: ActionContext<S, R>, areaId: number): Promise<IStatusDictionary>;
    getTopicSubtopics(context: ActionContext<S, R>, subjectId: number): Promise<IStatusDictionary>;
}

const actions: IHierarchyActionTree<IHierarchyState, IState> = {

    // Fetch area categories.  This kicks off the hierarchy chain fetch.
    async getAreaCategories(context: ActionContext<IHierarchyState, IState>,
                            areaOfStudy: number | null | undefined): Promise<IStatusDictionary> {
        store.commit('hierarchy/updateHierarchyUpdating', true);
        let res;
        if (!areaOfStudy) {
          res = await context.rootState.axios.get('/api/test/hierarchy/area-category');
        } else {
          res = await context.rootState.axios.get(
            `/api/test/hierarchy/area-of-study/${areaOfStudy}/area-category`);
        }
        const response = res.data as IStatusDictionary;
        if (response.status === 1) {
            context.commit('updateAreaCategories', response.value);
            const state = context.state;
            const areaCategories = response.value;
            const val = areaCategories.find(o => o.id === state.preconfiguredQuizHierarchy.QCFG_CAT_ID) ||
                areaCategories[0];
            store.commit('hierarchy/updateAreaCategorySelected', val);

            // Now fetch the areas
            if (state.areaCategorySelected) {
                await store.dispatch('hierarchy/getAreas', state.areaCategorySelected.id);
            }

        } else {
            store.commit('hierarchy/updateHierarchyUpdating', false);
        }

        return response;
    },

    async getAreas(context: ActionContext<IHierarchyState, IState>,
                   areaCategoryId: number): Promise<IStatusDictionary> {
        log.debug('Fetching areas for', areaCategoryId);

        const anyLevel = context.state.anyLevel;
        store.commit('hierarchy/updateHierarchyUpdating', true);
        let res = await context.rootState.axios.get(`/api/test/hierarchy/areas/${areaCategoryId}`);
        const response = res.data as IStatusDictionary;
        if (response.status === 1) {
            // Any level should only start at the Area level.  So add it if
            // necessary.
            const areas = response.value;
            if (anyLevel === HierarchyLevel.Area) {
                areas.splice(0, 0, AnyElement);
            }
            context.commit('updateAreas', areas);

            // Set the selected area.
            const state = context.state;
            const val = areas.find(
                area => area.id === state.preconfiguredQuizHierarchy.QCFG_AREA_ID) || areas[0];
            store.commit('hierarchy/updateAreaSelected', val);
            store.dispatch('hierarchy/getSubjects', val.id);
        } else {
            store.commit('hierarchy/updateHierarchyUpdating', false);
        }

        return response;
    },

    async getSubjects(context: ActionContext<IHierarchyState, IState>, areaId: number | string)
            : Promise<IStatusDictionary> {
        log.debug('Fetching subjects for', areaId);
        store.commit('hierarchy/updateHierarchyUpdating', true);

        let response;
        const state = context.state;
        // If the parent area is Any, then the only option will be any.
        if (areaId === AnyElement.id) {
            context.commit('updateSubjects', [AnyElement]);
            store.dispatch('hierarchy/getTopicSubtopics', areaId);
            response = {status: 1} as IStatusDictionary;
            store.commit('hierarchy/updateSubjectSelected', AnyElement);
        } else {
            response = await context.rootState.axios.get(
                `/api/test/hierarchy/subjects/${areaId}`);
            response = response.data as IStatusDictionary;

            if (response.status === 1) {

                // If anylevel starts @ the subjects level then add any element.
                if (context.state.anyLevel) {
                    response.value.splice(0, 0, AnyElement);
                }

                context.commit('updateSubjects', response.value);

                // Set the selected subject.
                const areas = response.value;
                const val = areas.find(
                    subject => subject.id === state.preconfiguredQuizHierarchy.QCFG_SUBJ_ID) || areas[0];
                store.commit('hierarchy/updateSubjectSelected', val);
                store.dispatch('hierarchy/getTopicSubtopics', val.id);
            } else {
                store.commit('hierarchy/updateHierarchyUpdating', false);
            }
        }

        return response;
    },

    async getTopicSubtopics(context: ActionContext<IHierarchyState, IState>, subjectId: number | string)
            : Promise<IStatusDictionary> {
        store.commit('hierarchy/updateHierarchyUpdating', true);

        // If parent is any, then topic/subtopic may only be any.
        let response;

        // AnyElement specified @ the topic level sets the select to just any element.
        // It does not make sense to have it setup @ topic / subtopic because of sectioning.
        if (subjectId === AnyElement.id || context.state.anyLevel === HierarchyLevel.TopicSubtopic) {
            context.commit('updateTopicSubtopics', [AnyElement]);
            store.commit('hierarchy/updateTopicSubtopicSelected', AnyElement);
            response = {status: 1} as IStatusDictionary;
        } else {
            response = await context.rootState.axios.get(
                `/api/test/hierarchy/topics-subtopics/${subjectId}`);
            response = response.data as IStatusDictionary;

            if (response.status === 1) {
                context.commit('updateTopicSubtopics', response.value);

                // Set the selected topic / subtopic.
                const state = context.state;
                const topicSubtopics = response.value;
                const val = topicSubtopics.find(
                    topicSubtopic => topicSubtopic.id === state.preconfiguredQuizHierarchy.QCFG_TOP_SUBT_ID) ||
                    topicSubtopics[0];
                store.commit('hierarchy/updateTopicSubtopicSelected',
                    val);

                // Break up sections
                const selectedTopicSubtopic = state.topicSubtopicSelected;
                if (selectedTopicSubtopic) {
                    if (selectedTopicSubtopic.questionCount > SECTION_SIZE) {
                        let numSections = Math.ceil(selectedTopicSubtopic.questionCount / SECTION_SIZE);
                        const sections = range(1, numSections + 1);
                        store.commit('hierarchy/updateSections', sections);
                        if (state.preconfiguredQuizHierarchy.QCFG_SECTION_ID &&
                            sections.find((sectionId) => sectionId ===
                              state.preconfiguredQuizHierarchy.QCFG_SECTION_ID)) {
                            store.commit('hierarchy/updateSectionSelected',
                                state.preconfiguredQuizHierarchy.QCFG_SECTION_ID);
                        } else {
                            store.commit('hierarchy/updateSectionSelected', 1);
                        }
                    } else {
                        store.commit('hierarchy/updateSections', []);
                        store.commit('hierarchy/updateSectionSelected');
                    }
                }
            }
        }
        store.commit('hierarchy/updateHierarchyUpdating', false);

        return response;
    },

    async setAnyLevel(context: ActionContext<IHierarchyState, IState>, anyLevel: HierarchyLevel) {
        context.commit('updateAnyLevel', anyLevel);

        if (context.state.hierarchyUpdating) {
             return;
        }

        const state = context.state;
        const oldAnyLevel = state.anyLevel || HierarchyLevel.Area;

        // Update the hierarchy nodes if not updating.
        const anyArray = [AnyElement];
        if (anyLevel != null) {
            if (anyLevel === HierarchyLevel.Area) {
                if (state.areas[0].id !== AnyElement.id) {
                    const newAreas = state.areas.slice();
                    newAreas.splice(0, 0, AnyElement);
                    context.commit('updateAreas', newAreas);
                }
            }
            if (anyLevel < HierarchyLevel.Subject) {
                if (state.subjects[0].id !== AnyElement.id) {
                    context.commit('updateSubjects', anyArray);
                }
            }
            if (anyLevel === HierarchyLevel.Subject) {
                if (state.subjects[0].id !== AnyElement.id) {
                    const newSubjects = state.subjects.slice();
                    newSubjects.splice(0, 0, AnyElement);
                    context.commit('updateSubjects', newSubjects);
                }
            }
            if (anyLevel <= HierarchyLevel.TopicSubtopic) {
                if (state.topicSubtopics[0].id !== AnyElement.id) {
                    context.commit('updateTopicSubtopics',
                        [new TopicSubtopic('any', 'Any', NaN)]);
                }
            }

            context.commit('updateAreaSelected', state.areas[0]);
            context.commit('updateSubjectSelected', state.subjects[0]);
            context.commit('updateTopicSubtopicSelected', state.topicSubtopics[0]);
        } else {
            // Reconfigure the hierarchy starting @ the highest level of any.
            switch (oldAnyLevel) {
                case HierarchyLevel.Area:
                    store.dispatch('hierarchy/getAreas', state.areaCategorySelected!.id);
                    break;
                case HierarchyLevel.Subject:
                    store.dispatch('hierarchy/getSubjects', state.areaSelected!.id);
                    break;
                case HierarchyLevel.TopicSubtopic:
                    store.dispatch('hierarchy/getTopicSubtopics', state.subjectSelected!.id);
                    break;
            }
        }
    }
};

/**
 * Create a registration module.
 *
 * @param state initial state for the registration module.
 */
export function createModule(state: IHierarchyState | null = null): Module<IHierarchyState, IState> {
    if (state === null) {
        state = Object.assign({}, HierarchyState);
    }

    return {
        namespaced: true,
        state,
        mutations,
        actions,
        strict: debug
    } as Module<IHierarchyState, IState>;
}

// Module
export default createModule();

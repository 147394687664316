

















import jsLogger from 'js-logger';
import * as Logger from 'js-logger';
import Vue from 'vue';
import {Component, Watch} from 'vue-property-decorator';
import {IStatusDictionary, Status} from '@/util/apiresponse';
import Question from '@/model/quizHierarchy/question';
import {ISearchResult} from '@/model/search';
import SearchResults from '@/components/search/search-results.vue';
import SearchPaginator from '@/components/search/paginator.vue';
import {Route} from 'vue-router';

const logger = Logger.get('cmp.Search');

if (process.env.NODE_ENV !== 'production') {
    logger.setLevel(jsLogger.DEBUG);
}

const ERROR_PERFORMING_SEARCH = 'Error performing your search, please try again later.';

@Component(
  {
    components: { SearchResults, SearchPaginator }
  }
)
export default class Search extends Vue {
  query = '';
  errorMessage = '';
  amSearching = false;
  pageNum = 0;
  questions: Question[] = [];
  searchResults: ISearchResult | null = null;

  @Watch('$route')
  async handleRouteChange(to: Route) {
    logger.debug('Search route updated', to);
    await this.handleSearchParams();
  }

  onSearch() {
    if (!this.query) {
      this.errorMessage = 'You must enter a query to search.';
    }

    this.$router.push({
      name: 'dashboard-search-query',
      params: {
        query: this.query
      }
    });
  }

  async search(query: string, pageNumber: number = 1): Promise<IStatusDictionary> {
    logger.debug('Searching for', query, `page ${pageNumber}`);
    try {
      this.amSearching = true;
      let res = await this.$store.state.axios.get('/api/search/do', {
        params: {
          query,
          page: pageNumber
        }
      });
      res = res.data as IStatusDictionary;
      logger.debug('Search response', res);
      return res;
    } catch (e) {
      return {
        status: Status.Fail,
        error_msg: ERROR_PERFORMING_SEARCH
      } as IStatusDictionary;
    } finally {
      this.amSearching = false;
    }
  }

  handleSearchResults(searchResultsDict: IStatusDictionary) {
    if (!searchResultsDict.status) {
      this.errorMessage = ERROR_PERFORMING_SEARCH;
      console.log(searchResultsDict.error_msg);
    } else {
      const searchResults = searchResultsDict.value as ISearchResult;
      this.pageNum = searchResults.pageNum;

      searchResults.questions.map((question) => {
        this.questions.push(question);
      });

      this.searchResults = searchResults;
    }
  }

  async handleSearchParams() {
    const pageNum = Number(this.$route.params.page) || 1;
    const searchResultsDict = await this.search(this.$route.params.query, pageNum);
    this.handleSearchResults(searchResultsDict);
  }

  async mounted() {
    logger.debug('Mounted', this.$route.params);
    if (this.$route.params.query) {
      this.query = this.$route.params.query;
      await this.handleSearchParams();
    }
  }
}

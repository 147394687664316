import { Module, MutationTree, Mutation } from 'vuex';
import IComment from '@/model/discuss/comment';
import {IState} from '@/store/state';
import Question from '@/model/quizHierarchy/question';
import IDiscussQuestion from '@/model/discuss/question';

const debug = process.env.NODE_ENV !== 'production';

export interface IQuestionDiscussState {
  // Actual question from the quiz hierarchy.
  testQuestion: Question | null;

  // The question used for discussion.
  question: IDiscussQuestion;
}

export const DefaultQuestionDiscussState = {
  testQuestion: null,
  question: {
    qid: -1,
    question: 'not set',
    answer: 'not set',
    comments: [],
    graphics: [],
    assets: [],
    reference: ''
  } as IDiscussQuestion
};

export interface IQuestionDiscussionMutations<S> extends MutationTree<S> {
  setQuestion: Mutation<S>;
  commentDelete: Mutation<S>;
  commentNew: Mutation<S>;
  commentEdit: Mutation<S>;
}

export interface IParentChildComment {
  parent: IComment | undefined;
  comment: IComment;
}

function findCommentContainer(commentId: number, comments: IComment[]): IComment[] | undefined {
  let comment = comments.find((c) => c.id === commentId);
  if (comment) {
    return comments;
  }

  for (comment of comments) {
    let otherComments = findCommentContainer(commentId, comment.replies);
    if (otherComments) {
      return otherComments;
    }
  }
}

function findComment(commentId: number, comments: IComment[]): IComment | undefined {
  const container = findCommentContainer(commentId, comments);
  if (!container) {
    return container;
  }

  const n = container.findIndex((comment) => comment.id === commentId);
  return container[n];
}

const mutations: IQuestionDiscussionMutations<IQuestionDiscussState> = {
  // commit('questionDiscuss/setQuestion')
  setQuestion(state: IQuestionDiscussState, question: Question | IDiscussQuestion) {
    if (question instanceof Question) {
      state.question = question.asDiscussQuestion();
    } else {
      state.question = question;
    }
  },

  commentDelete(state: IQuestionDiscussState, comment: IComment) {
    // So, the comments in IDiscussQuestion is really a reference to a real question.
    // That means modifying state.question also modifies state.testQuestion.
    const parentContainer = findCommentContainer(comment.id, state.question.comments);
    if (parentContainer) {
      let n = parentContainer.indexOf(comment);
      parentContainer.splice(n, 1);
    }
  },

  commentNew(state: IQuestionDiscussState, parentChildComment: IParentChildComment) {
    // Append the posted comment.
    if (parentChildComment.parent === null) {
      state.question.comments.push(parentChildComment.comment);
    } else {
      const parentContainer = findCommentContainer(parentChildComment.parent!.id, state.question.comments);
      const parent = parentContainer!.find((c) => c.id === parentChildComment.parent!.id);
      parent!.replies.push(parentChildComment.comment);
    }
  },

  commentEdit(state: IQuestionDiscussState, updatedComment: IComment) {
    const comment = findComment(updatedComment.id, state.question.comments);
    comment!.comment = updatedComment.comment;
  }

};

export function createModule(state: IQuestionDiscussState | null = null): Module<IQuestionDiscussState, IState> {
  if (state == null) {
    state = DefaultQuestionDiscussState;
  }

  return {
    namespaced: true,
    state,
    mutations,
    strict: debug
  } as Module<IQuestionDiscussState, IState>;
}

export default createModule(null);

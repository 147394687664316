





















































































import Vue from 'vue';
import { User } from '@/model/user';
import {Mutation, State} from 'vuex-class';

import Score from '@/model/quizHierarchy/score';
import Component from 'vue-class-component';
import QuizHierarchy from '@/model/quizHierarchy';
import { ITakeTestState } from '@/store/modules/taketest';
import Question from '@/model/quizHierarchy/question';
import Answer from '@/model/quizHierarchy/answer';
import GraphicsTab from '@/components/dashboard/study/test/GraphicsTab.vue';
import MissedQuestionWarning from '@/components/dashboard/study/test/missed-question-warning.vue';
import QuizInfo from '@/components/dashboard/study/test/quiz-info.vue';
import * as Logger from 'js-logger';
import { Route } from 'vue-router';
import DiscussQuestion from '@/components/discuss/discuss-question.vue';
import bus from '@/util/eventbus';

const log = Logger.get('take-test');

interface IAnswerAndLetter {
  letter: string;
  answer: Answer;
}

// Passing props to routes, scroll down to CTF0s answer.
// https://stackoverflow.com/questions/37937262/passing-props-to-vue-js-components-instantiated-by-vue-router

// Register the router hooks with their names
Component.registerHooks([
  'beforeRouteLeave'
]);

@Component(
  {
    components: {
      GraphicsTab,
      QuizInfo,
      DiscussQuestion,
      MissedQuestionWarning
    }
  }
)
export default class TakeTest extends Vue {

  _correctAnswer = false;
  selectedLetter = '';
  answersAndLetters: IAnswerAndLetter[] = [];
  activeAsset = '';
  assetLoading = false;
  currentQuestion = 0;
  numCorrect = 0;
  showBugReportSpeedDial = false;
  waitingForNextQuestion = false;

  @Mutation('takeTest/resetMissedQuestions') resetMissedQuestions: any;
  @Mutation('takeTest/addMissedQuestion') addMissedQuestion: any;
  @Mutation('takeTest/updateScore') updateScore: any;
  @Mutation('takeTest/addScore') addScore: any;
  @Mutation('takeTest/incrementMissedQuestionTrackerStat') incrementMissedQuestionTrackerStat: any;
  @Mutation('takeTest/incrementCorrectQuestionTrackerStat') incrementCorrectQuestionTrackerStat: any;
  @Mutation('updateShowReportBugDlg') updateShowReportBugDlg: any;
  @Mutation('questionDiscuss/setQuestion') setQuestionDiscussQuestion: (question: Question) => void;

  @State('chatOpen')
  chatOpen: boolean;

  @State('chatEnabled')
  chatEnabled: boolean;

  created() {
    const _global = window as any;
    _global.onAssetLoad = () => this.assetLoading = false;
    this.numCorrect = 0;
    this.currentQuestion = 0;
    this.resetMissedQuestions();
    this.fillAnswersAndLetters();
    if (!this.amChatting) {
      this.attachKeyPressHandler();
    }
    bus.$on('amPostingOrEditingReply', this.onDiscussQuestion);
    this.setQuestionDiscussQuestion(this.question);
  }

  destroyed() {
    this.detachKeyPressHandler();
  }

  attachKeyPressHandler() {
    log.info('attaching keypress handler');
    window.addEventListener('keypress', this.onKeyPressed);
  }

  detachKeyPressHandler() {
    log.info('removing keypress handler');
    window.removeEventListener('keypress', this.onKeyPressed);
  }

  beforeRouteLeave(to: Route, from: Route, next: (b?: boolean) => void) {
    // End of test, ignore route change.
    if (to.name === 'dashboard-study-test-results') {
      return next();
    }

    if (process.env.NODE_ENV !== 'production') {
      return next();
    }

    const answer = window.confirm('Do you really want to leave? You will lose testing progress.');
    if (answer) {
      next();
    } else {
      next(false);
    }
  }

  onKeyPressed(e: KeyboardEvent) {
    if (this.amChatting) {
      return;
    }

    if (this.waitingForNextQuestion) {
      if (this.endOfTest) {
        this.finishTest();
      } else {
        this.nextQuestion();
      }
      return;
    }

    const letterPressed = e.key.toUpperCase();
    const answerIndex = e.key.toUpperCase().charCodeAt(0) - 'A'.charCodeAt(0);

    this.answerSelected({
      letter: letterPressed,
      answer: this.question.Answers[answerIndex]
    } as IAnswerAndLetter);
  }

  showAsset(asset: string) {
    const previousActiveAsset = this.activeAsset;
    this.activeAsset = asset;
    this.assetLoading = true;

    if (previousActiveAsset !== '') {
      const element = document.getElementById('pdfIframe') as HTMLIFrameElement;
      if (element != null) {
        element!.contentDocument!.location!.reload();
      }
    }
  }

  makeBugReportObject(additionalOptions?: any) {
    const o = {
      action: 'Taking a test',
      questionId: this.question.Id,
      question: this.question.QuestionText,
      answers: this.question.Answers.map((answer) => answer.AnswerText)
    };

    if (additionalOptions) {
      Object.assign(o, additionalOptions);
    }

    return o;
  }

  reportBrokenImage() {
    this.updateShowReportBugDlg(
      this.makeBugReportObject({
        dialogPrefills: {
          subject: 'Missing or Incorrect Graphics',
          description: 'One or more graphics is missing or incorrect'
        }
      })
    );
  }

  reportBrokenQuestion() {
    this.updateShowReportBugDlg(
      this.makeBugReportObject({
        dialogPrefills: {
          subject: 'Problem with question',
          description: ''
        }
      })
    );
  }

  showReportBugDlg() {
    // You can update the showReportBugDlg with an object which will be
    // posted to the server.
    this.updateShowReportBugDlg(this.makeBugReportObject());
  }

  finishTest() {
    this.handleAnswer();

    // Increment current question so the score calculation is correct.
    this.currentQuestion++;

    this.updateScore(this.score);

    this.addScore({
            Date: new Date().toISOString().replace('T', ' '),
            Score: this.takeTest.score
          } as Score);

    // End of test?  Goto the end of test view.
    this.$router.push({
      name: 'dashboard-study-test-results',
    });
  }

  handleAnswer() {
    if (!this.correctAnswer) {
      this.addMissedQuestion(this.question);
      this.incrementMissedQuestionTrackerStat(this.question);
    } else {
      this.numCorrect++;
      this.incrementCorrectQuestionTrackerStat(this.question);
    }
  }

  /**
   * Called whenever a user is editing or creating a new discussion post.
   *
   * @param amEditing: If true, the user is actively editing.  If false, the
   * user has canceled or completed their edit.
   */
  onDiscussQuestion(amEditing: boolean) {
    if (amEditing) {
      this.detachKeyPressHandler();
    } else {
      this.attachKeyPressHandler();
    }
  }

  nextQuestion() {
    this.waitingForNextQuestion = false;
    this.handleAnswer();
    this.selectedLetter = '';
    this.currentQuestion += 1;
    this.fillAnswersAndLetters();
    this.setQuestionDiscussQuestion(this.question);
  }

  /**
   * Fill answersAndLetters.
   */
  fillAnswersAndLetters() {
    this.answersAndLetters.splice(0, this.answersAndLetters.length);
    let letter = 'A'.charCodeAt(0);
    const style = '';
    this.question.Answers.forEach(
      (answer) => {
        this.answersAndLetters.push({
          letter: String.fromCharCode(letter++),
          answer,
          style
        } as IAnswerAndLetter);
    });
  }

  // Handle an answer selection.
  answerSelected(answerAndLetter: IAnswerAndLetter) {
    this.waitingForNextQuestion = true;

    // Don't allow multiple selections.
    if (this.selectedLetter) {
      return;
    }
    this.selectedLetter = answerAndLetter.letter;
  }

  get hasAssets() {
    return !this.quizHierarchy.IsFinalExam && this.question.Assets.length > 0;
  }

  get takeTest(): ITakeTestState {
    return this.$store.state.takeTest as ITakeTestState;
  }

  get numberOfQuestions() {
    return this.quizHierarchy.Questions.length;
  }

  get quizHierarchy(): QuizHierarchy {
    return this.takeTest.quizHierarchy as QuizHierarchy;
  }

  get score() {
    if (this.currentQuestion === 0) {
      return 100;
    }

    return Math.round(100 * (this.numCorrect / (this.currentQuestion)));
  }

  get user(): User {
    return this.$store.state.user;
  }

  get question(): Question {
    return this.quizHierarchy.Questions[this.currentQuestion];
  }

  get hasGraphics(): boolean {
    return this.question.Graphics.length > 0;
  }

  get hasReference(): boolean {
    return !this.quizHierarchy.IsFinalExam && this.question.Reference != null;
  }

  // True if a correct answer is selected.
  get correctAnswer(): boolean {
    if (!this.selectedLetter) {
      return false;
    }

    return this._correctAnswer;
  }

  // True if an incorrect answer is selected.
  get incorrectAnswer(): boolean {
    if (!this.selectedLetter) {
      return false;
    }

    return !this._correctAnswer;
  }

  get endOfTest(): boolean {
    return (this.currentQuestion + 1) === this.takeTest.quizHierarchy.Questions.length;
  }

  get amChatting(): boolean {
    return this.chatOpen && this.chatEnabled;
  }

  answerClass(answerAndLetter: IAnswerAndLetter): string {
    if (this.selectedLetter !== '') {
      // An answer is selected, only highlight the selected answer
      if (this.selectedLetter === answerAndLetter.letter) {
        if (answerAndLetter.answer.IsCorrect) {
          this._correctAnswer = true;
          return 'subheading correct-answer padded-qa nohover';
        }
        this._correctAnswer = false;
        return 'subheading selected-answer padded-qa nohover';
      }

      // Answer does not match the selected answer, so highlight the answer
      // if it is the correct answer.
      if (answerAndLetter.answer.IsCorrect) {
        return 'subheading correct-answer padded-qa nohover';
      }

      // Disable hovering for the non-selected answers.
      return 'subheading padded-qa nohover';
    }

    return 'subheading padded-qa';
  }
}

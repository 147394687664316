
import ConfirmPurchase from './confirmpurchase.vue';
import { Component } from 'vue-property-decorator';
import {
    IPurchaseStudyguideCookie,
    makeStudyguide,
    monitorStudyguideCreation
} from '@/views/studyguide/sg-create.vue';
import * as Logger from 'js-logger';
import { Mutation, State } from 'vuex-class';
import { User } from '@/model/user';

const log = Logger.get('cfrm-purchase-sguide');

@Component
export default class ConfirmPurchaseStudyguide extends ConfirmPurchase {
  @Mutation('notification/setQueuedStudyguideCreation')
  setQueuedStudyguideCreation: any;

  @Mutation('notification/addQueuedStudyguideDownload')
  addQueuedStudyguideDownload: any;

  @Mutation('user/updateUser')
  updateUser: any;

  @State('user')
  user: User;

  async onSuccessfulPurchase() {
    const sginfo = this.$cookies.get(
      'purchase-studyguide'
    ) as IPurchaseStudyguideCookie;

    this.successMessage = 'Transaction processed successfully.  One second, making your studyguide.';
    const result = await makeStudyguide(sginfo.sgtype, sginfo.sgid);

    if (!result.success) {
      log.error(result.errorMsg);
      this.errorMessage = result.errorMsg;
      return;
    }

    this.setQueuedStudyguideCreation(result.trackingDict);
    log.info('Setting queued studyguide creation =>', result.trackingDict);
    const monitorResult = await monitorStudyguideCreation(sginfo.sgname, result.location);
    if (!monitorResult.success) {
      log.error(monitorResult.errorMsg);
      this.errorMessage = monitorResult.errorMsg;
      return;
    }

    this.setQueuedStudyguideCreation(null);
    this.addQueuedStudyguideDownload(monitorResult.studyguideDownload);

    // Studyguide created successfully, update the user token count.
    const user = Object.assign({}, this.user);
    user.studyguideTokens--;
    this.updateUser(user);
    this.successMessage = 'Your studyguide is ready.';
  }
}
















import Component from 'vue-class-component';
import Vue from 'vue';
import TodaysTest from './home/TodaysTest.vue';
import ScoreHistory from './home/ScoreHistory.vue';
import Discuss from './home/Discuss.vue';
import News from './home/News.vue';
import { User } from '@/model/user';
import '@/components/styles.css';

@Component({
  components: {
    TodaysTest, ScoreHistory, News, Discuss
  }
})
export default class Dashboard extends Vue {
  user?: User;

  created() {
    this.user = new User(this.$store.state.user);
  }
}
